import React from 'react';
import { FaSearch, FaBell } from 'react-icons/fa';
import './Navbar.css';
import { CgProfile } from "react-icons/cg";


const Navbar = () => {
  return (
    <div className="navbar">
      <div className="search-container">
        <div className="search-icon-container">
          <FaSearch className="search-icon" />
        </div>
        <input type="text" placeholder="Search..." className="search-input" />
      </div>
      <div className="icons-container">
        <div className="icon">
          <FaBell />
        </div>
        <div className="profile-icon">
          <CgProfile />
        </div>
      </div>
    </div>
  );
}

export default Navbar;
