import React, { useRef } from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';

const Preview = ({ text }) => {
  const previewRef = useRef(null);
  // Configuration for better-react-mathjax
  return (
    <div ref={previewRef}>
        <MathJax>{<div dangerouslySetInnerHTML={{ __html: text }} /> }</MathJax>
    </div>
  );
};

export default Preview;
