import OpenAI from "openai";
const openai = new OpenAI({
  apiKey:
    "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",
  dangerouslyAllowBrowser: true,
});

export const generateText = async (text, onUpdate) => {
  try {
    const response = await openai.chat.completions.create({
      model: "gpt-4o-mini",
      messages: [
        {
          role: "user",
          content: text,
        },
      ],
      stream: true,
    });

    // const reader = response.body.getReader();

    // while(true){
    //     const { done, value } = await reader.read();
    //     if (done) {
    //         console.log("Stream complete");
    //         break;
    //     }
    //     console.log("Received:", value);
    // }
    for await (const chunk of response) {
      const content = chunk.choices[0]?.delta?.content;
      // console.log(chunk);
      // console.log("Received:", content);
      if (content) {
        if (content == "") {
          onUpdate((prev) => prev + "\n");
        } else {
          onUpdate((prev) => prev + content);
        }
      }
    }

    // console.log("OpenAI response:", response);
    // return response.choices[0].message.content;
  } catch (error) {
    console.error("Error in generating text:", error);
  }
};
