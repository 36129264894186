import React from "react";
import classes from "./TestSubmitted.module.css";
import { Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
  
const TestSubmitted = ({ testDetails, quizId }) => {
  const params = window.location.search;
  const source = new URLSearchParams(params).get("source");
  // console.log("source", source);
  const navigate = useNavigate();
  return (
    <div className={classes.outerContainer}>
      <div className={classes.horizontalLine}>
        <h3 style={{ color: "#fff", marginBottom: "0px" }}>
          {testDetails?.title}
        </h3>
        <i
          className="fa-solid fa-circle-user fa-xl"
          style={{ color: "#fff" }}
        ></i>
      </div>
      <div className={classes.innerContainer}>
        <div className={classes.subInnerContainer}>
          <h1>Thank You!</h1>
          <Image src={"ThankYou.png"} className={classes.image} />
          <Button
            variant="success"
            style={{ width: "200px", marginTop: "20px" }}
            onClick={() => {
              if (source === "app") {
                window.ReactNativeWebView.postMessage("testSubmitted");
                navigate("/ViewTestSubmission", { state: { quizId } });
              } else {
                navigate("/");
              }
            }}
          >
            {source === "app" ? "View Evaluation" : "Go to Dashboard"}
          </Button>
          <h5>Your Answers were submitted successfully</h5>
          <p>Powered by: Innovartan.com</p>
        </div>
      </div>
    </div>
  );
};

export default TestSubmitted;
