import React, { useState, useEffect, useRef } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";

const DropDown = ({ title, options, cls, cls1 }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };       
    }, []);

    return (
        <div className={`relative ${cls ?? 'w-1/5'}`} ref={dropdownRef}>
            <span onClick={() => setIsOpen(!isOpen)} className={`${cls1 ?? 'w-[85%]'} title bg-blue-800 px-2 rounded-md text-white text-sm py-3 flex items-center cursor-pointer`}>
                <span className='flex-1 truncate'>
                    {title}
                </span>
                <span className='ml-1'><IoMdArrowDropdown /></span>
            </span>
            <div className={`absolute w-[11rem] z-20 rounded-sm flex flex-col bg-white items-center ${isOpen ? 'block' : 'hidden'}`}>
                {options.map((item, idx) => (
                    <div key={idx} className="py-2 md:py-3 px-4 w-full text-left">
                        <span className="border-white-800 cursor-pointer hover:text-blue-800 hover:border-b hover:border-blue-800">{item}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default DropDown;
