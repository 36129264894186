import React, { useState, useEffect } from "react";
import "./Course.css";
import { useDispatch, useSelector } from "react-redux";
import { getMyCourses } from "../../../Store/API/API";
import { useNavigate } from "react-router-dom";
import Spinner from "../../UserInterface/Spinner/Spinner";
const Courses = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [myCourses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispstch=useDispatch();
  const navigate = useNavigate();
  const fetchCourses = async () => {
    const data = await getMyCourses(userState.userId, userState.token,dispstch);
    if (data) {
      setCourses(data.shop);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchCourses();
  }, [userState]);

  const SectionCard = ({ cardType, data, isVedio }) => {
    return (
      <div
        className="sectionCard"
        onClick={() => {
          navigate("/course-details", { state: { course_id: data.course_id } });
        }}
      >
        <img
          src={data?.cover_photo_url || data?.thumb_nail}
          className="cardImage"
        ></img>
        <div className="contentContainer">
          <p className="cardHeading">{data?.name}</p>
          {!isVedio && (
            <p className="cardHeading">
              {data?.curriculum || data?.batch_name} |{" "}
              {data?.class || `${data?.fromTime} - ${data?.toTime}`} |{" "}
              {data?.subject || data?.date}
            </p>
          )}
          {<p className="cardHeading">{data?.title}</p>}
          <p className="cardSubHeading">By: Innovartan</p>
        </div>
      </div>
    );
  };
  const Section = ({ heading, cardType, test, data }) => {
    return (
      <div className="section">
        <div className="sectionOuter">
          <div>
            <i className="fa-solid fa-book-open"></i>
            <span style={{ marginLeft: "10px" }}>{heading}</span>
          </div>
        </div>
        <div className="horizontalLine"></div>
        <div className="sectionCardContainer">
          {data?.map((test, index) => (
            <SectionCard cardType={cardType} data={test} key={index} />
          ))}
        </div>
      </div>
    );
  };
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className="courseDashboard">
      {myCourses?.length > 0 ? (
        <Section
          heading={"My Courses"}
          cardType={"normal"}
          test={false}
          data={myCourses}
        />
      ) : null}

      {myCourses?.length === 0 && (
        <div className="noData">
          <h5>No Data Found</h5>
        </div>
      )}
    </div>
  );
};
export default Courses;
