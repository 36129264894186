import { createSlice } from "@reduxjs/toolkit";

const questionSlice = createSlice({
  name: "questions",
  initialState: {
    questions: [],
    testInfo: [],
  },
  reducers: {
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setTestInfo: (state, action) => {
      state.testInfo = action.payload;
    }
  },
});

export const questionActions = questionSlice.actions;
export default questionSlice;
