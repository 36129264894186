import React from "react";
import styles from "./AIButton.module.css"; // Import the CSS module
import { useNavigate } from "react-router-dom";

const AIButton = () => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.chatButton}
      onClick={() => {
        navigate("/innovartan-ai");
      }}
    >
      <div className={styles.iconWrapper}>
        <div className={styles.icon}>
          <img src="botImage.png" alt="AIBot" className={styles.aiIcon} />
        </div>
      </div>
      <span className={styles.text}>Ask me anything</span>
    </div>
  );
};

export default AIButton;
