import React, { useCallback, useState } from "react";
import { IoMdClose } from "react-icons/io";
import Preview from "../Preview/Preview";

const PreviewModal = ({ value, onClose, content }) => {
  const [showModal, setShowModal] = useState(true);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setTimeout(() => {
      onClose();
    }, 300);
  }, [onClose]);

  if (!showModal) return null;

  return (
    <div>
      <div
        className="
                    justify-center 
                    items-center 
                    flex 
                    overflow-x-hidden 
                    overflow-y-auto 
                    fixed 
                    inset-0 
                    z-[100] 
                    outline-none 
                    focus:outline-none
                    bg-neutral-800/70
                    h-full
                "
        onClick={handleClose}
      >
        <div
          className="
                        relative 
                        w-full
                        md:w-4/6
                        lg:w-[32rem]
                        mt-0
                        mx-auto 
                        h-auto
                        lg:h-auto
                        md:h-auto
                    "
        >
          <div
            className={`
                            translate
                            duration-300
                            h-full
                            ${showModal ? "opacity-100" : "opacity-0"}
                            ${showModal ? "scale-100" : "scale-0"}
                        `}
            onClick={(e) => e.stopPropagation()}
          >
            <div
              className="
                                translate
                                h-full
                                lg:h-auto
                                md:h-auto
                                border-0 
                                rounded-lg 
                                shadow-lg 
                                relative 
                                flex 
                                flex-col 
                                w-[100%] 
                                md:w-full
                                ml-auto
                                mr-3
                                bg-white 
                                outline-none 
                                focus:outline-none
                            "
            >
              <div className="flex items-center justify-between p-3 border-b border-solid border-gray-300 rounded-t">
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={handleClose}
                >
                  <IoMdClose />
                </button>
              </div>
              <div className="relative p-6 flex-auto">
                {content ?? <Preview text={value} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewModal;
