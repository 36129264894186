import React from "react";
import { useNavigate } from "react-router-dom";
import "./Sidebar.css";
import { BiSolidNotepad } from "react-icons/bi";
import { FaBookBookmark } from "react-icons/fa6";
import { IoSettings } from "react-icons/io5";
import { IoIosPaper } from "react-icons/io";
import { RiLogoutCircleLine } from "react-icons/ri";
import { BiSolidDashboard } from "react-icons/bi";
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import { userDataActions } from "../../../Store/Data/Slices/UserDataSlice";
// import profileImage from "images/profile.png";
// import wave from "../../../../public/images/wave.png";
// import logo from "../../../../public/images/logo.png";

const Sidebar = ({ onLogout }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const cookie = new Cookies()

  const handleLogout = async () => {
    try {

      cookie.remove("userData");
      dispatch(userDataActions.setUserData({}));


      localStorage.removeItem("token");
      // navigate("/login");
      if (typeof onLogout === "function") {
        onLogout();
      }
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };


  return (
    <div className="sidebar">
      <div className="logo">
        <img src={"logo.png"} alt="logo" />
      </div>
      <div className="content">
        <div className="name">
          <img src={"profile.png"} alt="Wave Icon" className="profile-icona" />
          <div className="wave-container">
            <img src={"wave.png"} alt="Wave Icon" className="wave-icon" />
            <span className="hi">Hi!</span>
            <span className="user">Student</span>
          </div>

        </div>
        <div className="dashboard">
          <BiSolidDashboard className="icon" />
          <a href="/dashboard"><span className="text">Dashboard</span></a>
        </div>
        <hr />
        <div className="headline">Academic</div>
        <div className="academic">
          <a href="Assignment" className="batches">
            <IoIosPaper className="icon" />
            <span className="text">Assignment</span>
          </a>
          <a href="/App" className="batches">
            <IoIosPaper className="icon" />
            <span className="text">Disscussion</span>
          </a>
          <a href="/feedback" className="batches">
            <IoIosPaper className="icon" />
            <span className="text">Feedback</span>
          </a>
          <a href="/Resources" className="report">
            <BiSolidNotepad className="icon" />
            <span className="text">Resources</span>
          </a>
          <a href="/upcomming" className="feedback">
            <FaBookBookmark className="icon" />
            <span className="text">Live Session</span>
          </a>
          <a href="/payment" className="feedback">
            <FaBookBookmark className="icon" />
            <span className="text">Payment</span>
          </a>

        </div>
        <hr />
        <div className="headline">Settings </div>
        <div className="academic">

          <a href="/Profile" className="batches">
            <IoIosPaper className="icon" />
            <span className="text">Profile</span>
          </a>
          <a href="/ChangePassword" className="report">
            <BiSolidNotepad className="icon" />
            <span className="text">Change Password</span>
          </a>

          <button className="button" onClick={handleLogout}>
            <RiLogoutCircleLine className="icon" />
            <span className="text">Logout</span>
          </button>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default Sidebar;
