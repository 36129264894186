import React, { useState, useEffect } from "react";
import "./Profile.css";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfile } from "../../../Store/API/API";
import { updateProfile } from "../../../Store/API/API";
import ChangePassword from "../ChangePassword/ChangePassword";
const Profile = () => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch=useDispatch();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobile_number: "",
    address: "",
    date_of_birth: "",
    gender: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
  });
  const fetchProfile = async () => {
    const data = await getUserProfile(userState.token, userState.userId,dispatch);
    if (data) {
      const tempData = {
        user_id: userState.userId,
        login_id: data.login_id,
        first_name: data.first_name,
        last_name: data.last_name,
        mobile_number: data.mobile_number,
        address: data.address,
        date_of_birth: data.date_of_birth || "",
        gender: data.gender,
        country: data.country,
        state: data.state,
        city: data.city,
        pincode: data.pincode,
      };
      setFormData(tempData);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProfile(userState.token, formData);
    //console.log(resp);
  };

  useEffect(() => {
    fetchProfile();
  }, [useState]);
  return (
    <div className="flex h-[100vh] w-[100%] flex-col overflow-auto bg-gray-100 ">
      <div className="batch-assignments dashboard-content flex md:w-[100%] w-full flex-col px-10 py-10 bg-gray-100 mt-10 ">
        <div className="edit-profile">
          <div className="form-row">
            <h2>My Profile</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                />
                <input type="hidden" name="user_id" value={formData.user_id} />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="login_id"
                  disabled={true}
                  value={formData.login_id}
                />
              </div>
              <div className="form-group">
                <label>Contact No.</label>
                <input
                  type="text"
                  name="mobile_number"
                  value={formData.mobile_number}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>Address</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>Date of Birth</label>
                <input
                  type="date"
                  name="date_of_birth"
                  value={formData.date_of_birth}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Gender</label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>Country</label>
                <select name="country" value={formData.country}>
                  <option value="india">India</option>
                </select>
              </div>

              <div className="form-group">
                <label>State</label>
                <select
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                >
                  <option value="Andaman and Nicobar Islands">
                    Andaman and Nicobar Islands
                  </option>
                  <option value="Andhra Pradesh">Andhra Pradesh</option>
                  <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                  <option value="Assam">Assam</option>
                  <option value="Bihar">Bihar</option>
                  <option value="Chandigarh">Chandigarh</option>
                  <option value="Chhattisgarh">Chhattisgarh</option>
                  <option value="Dadra and Nagar Haveli">
                    Dadra and Nagar Haveli
                  </option>
                  <option value="Delhi">Delhi</option>
                  <option value="Goa">Goa</option>
                  <option value="Gujarat">Gujarat</option>
                  <option value="Haryana">Haryana</option>
                  <option value="Himachal Pradesh">Himachal Pradesh</option>
                  <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                  <option value="Jharkhand">Jharkhand</option>
                  <option value="Karnataka">Karnataka</option>
                  <option value="Karnatka">Karnatka</option>
                  <option value="Kerala">Kerala</option>
                  <option value="Madhya Pradesh">Madhya Pradesh</option>
                  <option value="Maharashtra">Maharashtra</option>
                  <option value="Manipur">Manipur</option>
                  <option value="Meghalaya">&nsp;Meghalaya</option>
                  <option value="Mizoram">bMizoram</option>
                  <option value="Nagaland">Nagaland</option>
                  <option value="Odisha">Odisha</option>
                  <option value="Puducherry">Puducherry</option>
                  <option value="Punjab">Punjab</option>
                  <option value="Rajasthan">Rajasthan</option>
                  <option value="Tamil Nadu">Tamil Nadu</option>
                  <option value="Telangana">Telangana</option>
                  <option value="Tripura">Tripura</option>
                  <option value="Uttar Pradesh">Uttar Pradesh</option>
                  <option value="Uttarakhand">Uttarakhand</option>
                  <option value="West Bengal">West Bengal</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>City</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Pin Code</label>
                <input
                  type="text"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-buttons">
              <button type="button">Cancel</button>
              <button type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
      <ChangePassword />
    </div>
  );
};

export default Profile;
