import React, { useState, useEffect } from "react";
import "./Course.css";
import { useDispatch, useSelector } from "react-redux";
import { getCourseDetails } from "../../../Store/API/API";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Spinner from "../../UserInterface/Spinner/Spinner";
const CourseDetail = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [isLoading, setIsLoading] = useState(true);
  const [courseDetails, setCourse] = useState([]);
  const location = useLocation();
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const { course_id } = location.state;
  const fetchCourse = async () => {
    const data = await getCourseDetails(course_id, userState.userId,dispatch);
    if (data) {
      setCourse(data);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchCourse();
  }, [userState]);
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className="course-detail-container">
      <div className="course-content flex md:w-[100%] w-full flex-col px-10 py-10 mt-10 ">
        <div className="course-header">
          <div className="course-image-placeholder">
            <img
              src={courseDetails.cover_photo_url}
              className="cardImage"
            ></img>
          </div>
          <div className="course-info">
            <h1>
              <strong>{courseDetails.name}</strong>
            </h1>
            <ul>
              <li className="subDetail">
                {courseDetails?.subject}
                {" | "} {courseDetails?.class} {" | "}
                {courseDetails?.curriculum}
              </li>
              <li>{courseDetails?.sub_title}</li>
            </ul>
            <div className="course-meta">
              <span>
                Language: <strong>English</strong>
              </span>
              <span>
                Total Validity:{" "}
                <strong>{courseDetails.validity_days} days </strong>
              </span>
            </div>
          </div>
        </div>
        <div className="course-actions">
          <span className="course-price">
            Price : ₹ {courseDetails.content_amount}
          </span>
        </div>
        <div className="course-learn">
          <h2>
            <strong>What you'll learn</strong>
          </h2>
          <div dangerouslySetInnerHTML={{ __html: courseDetails.topic }}></div>
        </div>
        <div className="course-learn">
          <h2>
            <strong>Requirements</strong>
          </h2>
          <div
            dangerouslySetInnerHTML={{ __html: courseDetails.requirement }}
          ></div>
        </div>
        <div className="course-learn">
          <h2>
            <strong>Description</strong>
          </h2>
          <div
            dangerouslySetInnerHTML={{ __html: courseDetails.description }}
          ></div>
        </div>
      </div>
      <div className="course-sidebar">
        {courseDetails.can_buy === 1 ? (
          <button className="buy-now">Buy Now</button>
        ) : (
          <button
            className="buy-now"
            onClick={() => {
              navigate("/course-content", {
                state: { course_name: courseDetails.course_name },
              });
            }}
          >
            View Details
          </button>
        )}
        <div className="course-learn">
          <h2>
            <strong>This course includes </strong>
          </h2>
          <div
            dangerouslySetInnerHTML={{ __html: courseDetails.include }}
          ></div>
        </div>
      </div>
    </div>
  );
};
export default CourseDetail;
