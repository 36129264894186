import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

export default function MenuBar({
  takeTest,
  viewEvaluation,
  viewAnalysis,
  viewDetails,
  awaitingEvaluation,
  navigateParam,
  passedData,
  analysisURL,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const navigate = useNavigate();

  const handleNavigate = (navParam, passedData) => {
    console.log(navParam, passedData);
    if (navParam === "startTest") {
      navigate("/startTest", { state: { quizId: passedData } });
    } else if (navParam === "ViewTestSubmission") {
      navigate("/ViewTestSubmission", { state: { quizId: passedData } });
    } else if (navParam === "teacher-test-view") {
      navigate("/teacher-test-view", { state: { testId: passedData } });
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ width: "200px" }}
      >
        {awaitingEvaluation && (
          <MenuItem
            // onClick={() => {
            //   navigate("/ViewTestSubmission", passedData);
            // }}
            disabled
          >
            Awaiting Evaluation
          </MenuItem>
        )}
        {takeTest && (
          <MenuItem
            onClick={() => {
              handleNavigate("startTest", passedData);
            }}
          >
            Take Test
          </MenuItem>
        )}
        {viewEvaluation && (
          <MenuItem
            onClick={() => {
              handleNavigate("ViewTestSubmission", passedData);
            }}
          >
            View Evaluation
          </MenuItem>
        )}
        {viewAnalysis && (
          <MenuItem
            onClick={() => {
              window.open(analysisURL, "_blank");
            }}
          >
            View Analysis
          </MenuItem>
        )}
        {viewDetails && (
          <MenuItem
            onClick={() => {
              handleNavigate("teacher-test-view", passedData);
            }}
          >
            View Details
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
