import React, { useState, useEffect } from "react";
import "./Session.css";
import { Link } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { getGroupSession } from "../../../Store/API/API";
import Spinner from "../../UserInterface/Spinner/Spinner";
import Tooltip from "./Tooltip";
import CardView from "./CardView";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const CancelSessions = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [canceldata, setSessions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch=useDispatch();
  const batchState = useSelector((state) => state.applicationData.batchData);
  const fetchSessions = async () => {
    const data = await getGroupSession(userState, batchState.groupId, "cancel",dispatch);
    if (data) {
      // console.log(data);
      setSessions(data);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSessions();
    // eslint-disable-next-line
  }, [userState]);

  if (isLoading) {
    return <Spinner />;
  }
  const renderCancelSession = (item) => (
    <>
      <div>
        <strong>Start Date:</strong> <span>{item.date}</span>
      </div>
      <div>
        <strong>Start Time:</strong> <span>{item.fromTime}</span>
      </div>
      <div>
        <strong>Subject:</strong> <span>{item.session_subject}</span>
      </div>
      <div>
        <strong>Chapter:</strong> <span>{item.session_chapter}</span>
      </div>
      <div>
        <strong>Teacher:</strong> <span>{item.session_tutor_name}</span>
      </div>
      <div className="mx-auto text-sm text-slate-600">
        <strong>Reason:</strong>
        <Tooltip text={item.cancelReason}>
          <span className="truncated-text">
            {item.cancelReason.length > 50
              ? item.cancelReason.slice(0, 50) + "..."
              : item.cancelReason}
          </span>
        </Tooltip>
      </div>
    </>
  );

  return (
    <div className="flex h-[95vh] w-[100%]">
      <div className="overflow-auto flex md:w-[100%] w-full flex-col px-3 py-6 h-full bg-gray-100 mt-10 ">
        <div className="form-row">
          <div className="form-buttons">
            <Link
              className="mx-1 decoration-transparent sm:w-[7rem] text-center rounded-lg p-2 text-white hrcolor Buttons"
              to="/upcomming-session"
            >
              Upcoming
            </Link>
            <Link
              className="mx-1 decoration-transparent sm:w-[7rem] text-center rounded-lg p-2 text-white hrcolor Buttons"
              to="/past-session"
            >
              Past
            </Link>
            <Link
              className="mx-1 decoration-transparent sm:w-[7rem] text-center rounded-lg p-2 text-white activecolor Buttons"
              to="/cancel-session"
            >
              Canceled
            </Link>
          </div>
        </div>
        <div className="rounded-lg py-1 md:p-3 w-full  border-t-2 border-t-orange-500 bg-white main-container">
          <Table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <Thead>
              <Tr className="bg-[#0A3C87] text-white uppercase text-sm leading-normal">
                <Th className="py-2 px-0 text-center">Start Date</Th>
                <Th className="py-2 px-0 text-center">Time</Th>
                <Th className="py-2 px-0 text-center">Subject</Th>
                <Th className="py-2 px-0 text-center">Chapter</Th>
                <Th className="py-2 px-0 text-center">Teacher</Th>
                <Th className="py-2 px-0 text-center">Reason</Th>
              </Tr>
            </Thead>
            <Tbody className="text-gray-600 text-sm font-light">
              {canceldata?.length === 0 && (
                <Tr>
                  <Td
                    colSpan="7"
                    className="py-2 px-6 text-center text-orange-500 font-bold"
                  >
                    No Cancelled Sessions Found
                  </Td>
                </Tr>
              )}

              {canceldata?.map((item, index) => (
                <Tr
                  className="border-b border-gray-200 hover:bg-gray-100"
                  key={index}
                >
                  <Td className="py-2 px-0 text-center">{item.date}</Td>
                  <Td className="py-2 px-0 text-center">{item.fromTime}</Td>
                  <Td className="py-2 px-0 text-center">
                    {item.session_subject}
                  </Td>
                  <Td className="py-2 px-0 text-center">
                    {item.session_chapter}
                  </Td>
                  <Td className="py-2 px-0 text-center">
                    {item.session_tutor_name}
                  </Td>

                  <Td className="py-3 px-6 text-center">
                    {item.cancelReason ? (
                      <Tooltip text={item.cancelReason}>
                        <span className="truncated-text">
                          {item.cancelReason.length > 50
                            ? item.cancelReason.slice(0, 50) + "..."
                            : item.cancelReason}
                        </span>
                      </Tooltip>
                    ) : (
                      <span style={{ fontWeight: 700 }}>
                        No Reason Provided
                      </span>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        <CardView
          data={canceldata}
          renderContent={renderCancelSession}
          noDataMessage="No past sessions found."
        />
      </div>
    </div>
  );
};
export default CancelSessions;
