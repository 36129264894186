import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
  name: "userData",
  initialState: {
    userData: {
      token: "",
      userId: "",
      testId: "",
      groupId: "",
      firstname: "",
      lastname: "",
      userType: "",
    },
  },
  reducers: {
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const userDataActions = userDataSlice.actions;
export default userDataSlice;
