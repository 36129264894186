import React, { useEffect, useState } from "react";
import AttachmentBox from "../../UI/Box/AttachmentBox";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import {
  fetchAssignmentDetails,
  handleSubmitPost,
} from "../../../Store/API/User"; // Adjust the path according to your file structure
import PreviewModal from "../../Modals/PreviewModal";
import { Input, TextField } from "@mui/material";
import { TiAttachmentOutline } from "react-icons/ti";
import { handleFileChange } from "../../../Store/API/File";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "bootstrap";
import { applicationActions } from "../../../Store/Data/Slices/ApplicationSlice";
import Spinner from "../../UserInterface/Spinner/Spinner";
const AssignmentDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentAssignmentId = location.state.assignmentId;
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [isFetching, setIsFetching] = useState(true);

  const token = "98c1ec61f46858ef5bcce7f85f5286cbtutMjk0Mg==";
  // const token = userState?.token;
  const assignmentId = currentAssignmentId;
  const student_id = userState?.userId;
  const type = "batch";
  const group_id = batchState?.groupId;

  const handleOnChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    setIsLoading(true);
    
    const filesData = await Promise.all(
      selectedFiles.map(async (file) => {
        const { data } = await handleFileChange(file,dispatch);
        // console.log(data);
        let fileSizeInKB = data.file_size / 1024;
        fileSizeInKB = fileSizeInKB.toFixed(2);

        return {
          name: data.file_name,
          owner_id: student_id,
          url: data.file_url,
          mime: data.file_type,
          size: fileSizeInKB + " KB",
          size_bytes: data.file_size,
        };
      })
    );

    setIsLoading(false);
    setFiles([...files, ...filesData]);
  };

  const removeFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchAssignmentDetails(
          token,
          assignmentId,
          student_id,
          type,dispatch
        );
        // console.log(result);
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
        setIsFetching(false);
      }
    };

    getData();
  }, [currentAssignmentId, userState, batchState]);

  const handleOpenModal = () => {
    if (isOpenModal) {
      setIsOpenModal(false);
    } else {
      setIsOpenModal(true);
    }
  };

  const handleSubmitRemarks = async () => {
    if (remarks.length === 0) {
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Error while submitting assignment",
          alertType: "danger",
        })
      );
      setTimeout(() => {
        dispatch(
          applicationActions.setAlertData({
            isAlert: false,
            alertMessage: "",
            alertType: "",
          })
        );
      }, 3000);
      return;
    }
    const strAttachments = JSON.stringify(files);
    const data = await handleSubmitPost(
      token,
      assignmentId,
      group_id,
      student_id,
      remarks,
      strAttachments,dispatch
    );
    if (data.status) {
      handleOpenModal();
      dispatch(
        applicationActions.setAlertData({
          isAlert: true,
          alertMessage: "Assignment submitted successfully",
          alertType: "success",
        })
      );
    }
    setFiles([]);
    setRemarks("");
    setTimeout(() => {
      dispatch(
        applicationActions.setAlertData({
          isAlert: false,
          alertMessage: "",
          alertType: "",
        })
      );
    }, 3000);
  };

  // console.log(loading);

  const content = (
    <>
      <div className="content m-0 text-center">
        <span className="text-3xl font-bold">Submit Assignment</span>
        <TextField
          id="outlined-multiline-static"
          value={remarks}
          onChange={(e) => setRemarks(e.target.value)}
          multiline
          style={{ marginTop: "2rem" }}
          color="warning"
          focused
          fullWidth
          rows={3}
          label="REMARKS"
          variant="outlined"
        />
        <div className="flex">
          <input
            type="file"
            multiple
            onChange={(e) => handleOnChange(e)}
            style={{ display: "none" }}
            id="fileInput"
          />
        </div>
        {files.length > 0 && (
          <div className="flex flex-1 gap-2 pt-3 items-center flex-wrap w-full">
            {files.map((file, index) => (
              <div
                key={index}
                className="flex w-[8rem] px-3 py-2 truncate items-center p-1 bg-gray-200 rounded-md"
              >
                <p className="truncate text-sm text-slate-700 p-0 m-0 ">
                  {file.name} ({file.size})
                </p>
                <button
                  onClick={() => removeFile(index)}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="float-right mt-4 flex gap-2">
          <label className="" htmlFor="fileInput">
            <span
              className={`bg-orange-${
                isLoading ? "400" : "500"
              } flex cursor-pointer items-center gap-2 text-white rounded-lg p-2`}
            >
              {isLoading ? "Uploading.." : "Upload Attachments"}
            </span>
          </label>
          <span
            onClick={handleSubmitRemarks}
            className="bg-orange-500 px-3 flex cursor-pointer items-center gap-2 text-white rounded-lg p-2"
          >
            Submit
          </span>
        </div>
      </div>
    </>
  );

  if (isFetching) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex w-[100%] h-[93vh] overflow-auto">
      {data && (
        <div className="batch-assignments-details sm:pl-[3rem] flex my-5 w-[100%]  sm:w-full flex-col px-3 py-10 h-full ">
          <div className="form-row mb-5 ">
            <div className="form-buttons overflow-hidden flex flex-wrap gap-3 ">
              <button
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#FEA63C",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  color: "#000",
                  margin: "0 10px",
                  width: "120px",
                  height: "60px",
                  textAlign: "center",
                  padding: "5px",
                }}
                type="submit"
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/assignment");
                }}
              >
                Assignments List
              </button>
              <button
                style={{
                  backgroundColor: "#ff3d5d",
                  borderColor: "#ff3d5d",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  color: "#fff",
                  margin: "0 10px",
                  width: "120px",
                  height: "60px",
                  textAlign: "center",
                  padding: "5px",
                }}
                type="submit"
              >
                Assignment Detail
              </button>
              <button
                type="submit"
                style={{
                  backgroundColor: "#fff",
                  borderColor: "#FEA63C",
                  borderWidth: "2px",
                  borderStyle: "solid",
                  color: "#000",
                  margin: "0 10px",
                  width: "120px",
                  height: "60px",
                  textAlign: "center",
                  padding: "5px",
                }}
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/assignment/submittions", {
                    state: { assignmentId },
                  });
                }}
              >
                Submissions
              </button>
            </div>
          </div>
          <div className="flex items-center justify-start gap-3 flex-wrap">
            <div className="title bg-white p-3 px-4 border-l-orange-500 border-l-4 rounded-md shadow-md h-[100px] min-w-[200px] ">
              <h5>Title</h5>
              <span className="text-blue-800 text-sm">{data.title}</span>
            </div>
            <div className="data-time bg-white p-3 px-4 border-l-orange-500 border-l-4 rounded-md shadow-md h-[100px] min-w-[200px] ">
              <h5>Due Date-Time</h5>
              <span className="text-blue-800 text-sm">{data.due_date}</span>
            </div>
          </div>
          <div className="pt-12 pb-3">
            <h4>Description</h4>
            <div
              className="text-gray-500"
              dangerouslySetInnerHTML={{ __html: data.description }}
            ></div>
          </div>
          <div className="pt-12 pb-4">
            <div className="attachments-card w-[100%] md:w-[85%] bg-white py-3 border-l-orange-500 border-l-4 rounded-md shadow-md">
              <h5 className="pl-8">Attachments</h5>
              <div className="attachments flex gap-1 justify-start px-3 py-3 flex-wrap">
                {data?.attachments?.map((attachment, index) => (
                  <AttachmentBox key={index} attachment={attachment} />
                ))}
              </div>
            </div>
          </div>
          {data?.can_submit !== "1" && data.solution && (
            <div className="pt-6 pb-4">
              <div className="attachments-card w-[100%] md:w-[85%] bg-white py-3 border-l-orange-500 border-l-4 rounded-md shadow-md">
                <h5 className="pl-8">Solutions</h5>
                <div className="attachments flex gap-1 justify-start px-3 py-3 flex-wrap">
                  {data?.solution?.map((attachment, index) => (
                    <AttachmentBox key={index} attachment={attachment} />
                  ))}
                </div>
              </div>
            </div>
          )}
          {isOpenModal && (
            <PreviewModal content={content} onClose={handleOpenModal} />
          )}
          {data?.can_submit === "1" && (
            <div className="w-full">
              <button
                className="px-4 py-2 bg-blue-800 text-white rounded-lg"
                onClick={handleOpenModal}
              >
                {data?.button_title}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AssignmentDetails;
