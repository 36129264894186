import React from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./TestInfoModal.module.css";
import { useSelector } from "react-redux";

const TestInfoModal = ({ show, handleClose }) => {
    const questionState = useSelector((state) => state.questionData.testInfo);
  return (
    <div>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <Modal.Body className={classes.modalOuterContainer}>
          <div className={classes.outerContainer}>
            <div className={classes.horizontalLine}>
              <h3 style={{ color: "#fff", marginBottom: "0px" }}>
                {questionState?.title}
              </h3>
              <i
                className="fa-solid fa-circle-user fa-xl"
                style={{ color: "#fff" }}
              ></i>
            </div>
            <div className={classes.innerContainer}>
              <h3>{questionState?.title}</h3>
              <h4>Test Instructions :</h4>
              <div>
                {questionState?.description ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        "<p>" +
                        questionState?.description?.replace(/\r\n/g, "<br>") +
                        "</p>",
                    }}
                  />
                ) : (
                  <p>Loading details...</p>
                )}
              </div>
              <div className={classes.info}>
                <h5>Test Duration: {questionState?.timelimit}</h5>
                <h5>Total No. of Questions : {questionState?.all_questions} </h5>
              </div>
              <div
                style={{
                  marginLeft: "50px",
                  marginTop: "30px",
                }}
              >
                <h5>Total Marks : {questionState?.total_points}</h5>
              </div>
            </div>
            {/* </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TestInfoModal;
