import React from "react";
import classes from "./TestCard.module.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import MenuBar from "../../UI/MenuBar/MenuBar";

const TestCards = ({
  title,
  publishFrom,
  publishTo,
  subject,
  chapters,
  TestMode,
  Rank,
  quizId,
  Actions,
  token,
  takeTest,
  analyticURL,
}) => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.userData.userData);
  return (
    <div className={classes.outerContainer}>
      {userState.userType === "student" && (
        <div className={classes.infoContainer}>
          {takeTest === "1" && (
            <div
            // className={classes.takeButton}
            // onClick={() => {
            //   navigate("/startTest", { state: { quizId } });
            // }}
            >
              <MenuBar
                takeTest={true}
                navigateParam={"/startTest"}
                passedData={quizId}
              />
            </div>
          )}
          {takeTest === "3" && (
            <div
            // className={classes.takeButton}
            // onClick={() => {
            //   navigate("/ViewTestSubmission", { state: { quizId } });
            // }}
            >
              <MenuBar
                viewEvaluation={true}
                viewAnalysis={true}
                navigateParam={"ViewTestSubmission"}
                analysisURL={`http://quiz.edvanz.com/${analyticURL}`}
                passedData={quizId}
              />
            </div>
          )}
          {takeTest === "4" && (
            // <div className={classes.awaitingButton}>
            <MenuBar awaitingEvaluation={true} />
            // </div>
          )}
        </div>
      )}
      {userState.userType === "teacher" && (
        <div className={classes.infoContainer}>
          <div
            className={classes.takeButton}
            // onClick={() => {
            //   navigate("/teacher-test-view", { state: { testId: quizId } });
            // }}
          >
            <MenuBar
              viewDetails={true}
              navigateParam={"/teacher-test-view"}
              passedData={quizId}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default TestCards;
