import React, { useState } from "react";
import { FaFolder } from "react-icons/fa6";
import { FaFile } from "react-icons/fa";
import Zoom from "react-medium-image-zoom";
import classes from "./ResourceFolders.module.css";
import VideoPlayer from "./../Pages/Dashboard/Videoplayer/VideoPlayer";
import { useDispatch, useSelector } from "react-redux";
import { getRecordingTime } from "../../Store/API/API";
import Spinner from "../UserInterface/Spinner/Spinner";
const ResourceFolders = ({
  isGrid,
  folders,
  files,
  onFolderClick,
  directory,
  handleDirectoryClick,
}) => {
  // console.log("Folder Data", folders);
  // console.log("File Data", files);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedMP4, setSelectedMP4] = useState("");
  const [selectedPDF, setSelectedPDF] = useState(null);
  const [currentViewTime, setCurrentViewTime] = useState(0);
  const dispatch=useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const closeVideoModal = () => {
    setSelectedMP4("");
    setCurrentViewTime(0);
    setShowVideoModal(false);
  };
  const closePDFModal = () => {
    setSelectedPDF(null);
  };

  const handlePdfClick = (file) => {
    // console.log("PDF clicked:", file);
    const btobUrl = btoa(file.url);
    // console.log("btoa URL", btobUrl);
    setSelectedPDF(btobUrl);
  };

  const handleMp4Click = async (file) => {
    // console.log("MP4 clicked:", file);
    setSelectedMP4(file);
    const tempData = {
      user_id: userState.userId,
      content_type: "resource",
      content_id: file.content_id,
    };

    // console.log("Video Data", tempData);

    const data = await getRecordingTime(tempData, userState.token,dispatch);
    // console.log("Recording Time", data);
    if (data) {
      setCurrentViewTime(data.current_view_time);
      setShowVideoModal(true);
    }
  };

  const handleImageClick = (file) => {
    setSelectedFile(file);
  };

  const handleFileClick = (file) => {
    switch (file.mime) {
      case "application/pdf":
        handlePdfClick(file);
        break;
      case "video/mp4":
        handleMp4Click(file);
        break;
      case "image/jpeg":
      case "image/png":
      case "image/gif":
        handleImageClick(file);
        break;
      default:
        // console.log("Unknown file type:", file);
    }
  };
  return (
    <>
      <div className="directory mt-16">
        <span
          onClick={() => handleDirectoryClick(0)}
          className="cursor-pointer text-sm"
        >
          / {" >> "}
        </span>
        {directory &&
          directory.map((folder, idx) => (
            <span
              onClick={() => handleDirectoryClick(folder.folder_id)}
              className="cursor-pointer text-sm"
              key={idx}
            >
              {folder.folder_name} {" >> "}
            </span>
          ))}
      </div>
      <div className="flex flex-wrap relative ">
        {folders?.map((folder) => (
          <div
            className={
              isGrid
                ? `p-3 bg-white m-1 rounded-lg shadow-md flex-grow flex flex-col w-[6rem] py-4 justify-center items-center`
                : `w-[90%] bg-white flex p-4 rounded-lg items-center my-2 cursor-pointer`
            }
            key={folder.folder_id}
            onClick={() => onFolderClick(folder.folder_id, folder.folder_name)}
          >
            <FaFolder color="orange" size={25} />
            <span
              className={
                isGrid
                  ? `text-black truncate hover:text-blue-600 mt-2 text-center w-full px-2`
                  : `mx-3 my-auto text-lg`
              }
            >
              {folder.folder_name}
            </span>
          </div>
        ))}
        {files?.map((file) => (
          <div
            className={
              isGrid
                ? `p-3 bg-white m-1 rounded-lg shadow-md flex-grow flex flex-col w-[6rem] py-4 justify-center items-center`
                : `w-[90%] bg-white flex p-4 rounded-lg items-center my-2 cursor-pointer`
            }
            key={file.document_id}
            onClick={() => handleFileClick(file)}
          >
            <FaFile className="w-[20.8125px] h-[25px]" color="orange" />
            <span
              className={
                isGrid
                  ? `text-black truncate hover:text-blue-600 mt-2 text-center w-full px-2`
                  : `mx-3 truncate w-[80%] my-auto text-lg`
              }
            >
              {file.name}
            </span>
          </div>
        ))}
        {selectedFile && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-lg w-full flex flex-col">
              <Zoom>
                <img
                  src={selectedFile.url}
                  alt={selectedFile.name}
                  className="w-full h-auto cursor-pointer object-contain"
                />
              </Zoom>

              <button
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 w-[100px] self-center"
                onClick={() => setSelectedFile(null)}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {selectedPDF && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-3xl w-full flex flex-col">
              <iframe
                src={`https://app.innovartan.com/flipBook/flip.php?link=${selectedPDF}`}
                title={selectedPDF.name}
                className="w-full h-[70vh]"
              />
              <button
                className="mt-4 px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 w-[100px] self-center"
                onClick={closePDFModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
        {showVideoModal && (
          <div className={classes.videoModal}>
            <div className={classes.videoModalContent}>
              <span className={classes.close} onClick={closeVideoModal}>
                &times;
              </span>
              <VideoPlayer
                fileData={selectedMP4}
                currentViewTimeParent={currentViewTime}
                type={"resource"}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ResourceFolders;
