// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BatchCard_batchCardOuter__7gdQY {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
  /* height: 150px; */
  padding: 20px;
  font-size: 30px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  background: #96c27a;
  transition: all 0.3s ease; /* Added transition for smooth hover effect */
}

.BatchCard_batchCardOuter__7gdQY:hover {
  transform: scale(1.05);
  opacity: 0.7;
}

.BatchCard_container__9aKp7 {
  padding: 10px 20px; /* Combined horizontal and vertical padding */
  border-radius: 20px;
  margin-bottom: 20px;
}

.BatchCard_badge__RBfOz {
  margin-top: 15px;
  width: 100px;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 20px;
  display: flex; /* Added for alignItems */
  align-items: center;
  justify-content: center;
}

.BatchCard_cardText__T0y-n {
  font-size: 20px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
}

.BatchCard_badgeText__AoXNn {
  font-size: 15px;
  margin: 0;
}

.BatchCard_tutorName__wQQxj {
  font-size: 15px;
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Batch/BatchCard/BatchCard.module.css"],"names":[],"mappings":"AAAA;EACE,0CAA0C;EAC1C,mBAAmB;EACnB,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf;+EAC6E;EAC7E,mBAAmB;EACnB,yBAAyB,EAAE,6CAA6C;AAC1E;;AAEA;EACE,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,kBAAkB,EAAE,6CAA6C;EACjE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,aAAa,EAAE,yBAAyB;EACxC,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,SAAS;AACX;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".batchCardOuter {\n  background-color: rgba(255, 255, 255, 0.8);\n  border-radius: 15px;\n  /* height: 150px; */\n  padding: 20px;\n  font-size: 30px;\n  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,\n    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;\n  background: #96c27a;\n  transition: all 0.3s ease; /* Added transition for smooth hover effect */\n}\n\n.batchCardOuter:hover {\n  transform: scale(1.05);\n  opacity: 0.7;\n}\n\n.container {\n  padding: 10px 20px; /* Combined horizontal and vertical padding */\n  border-radius: 20px;\n  margin-bottom: 20px;\n}\n\n.badge {\n  margin-top: 15px;\n  width: 100px;\n  background-color: #ffffff;\n  padding: 5px;\n  border-radius: 20px;\n  display: flex; /* Added for alignItems */\n  align-items: center;\n  justify-content: center;\n}\n\n.cardText {\n  font-size: 20px;\n  color: #ffffff;\n  font-weight: 600;\n  letter-spacing: 1px;\n}\n\n.badgeText {\n  font-size: 15px;\n  margin: 0;\n}\n\n.tutorName {\n  font-size: 15px;\n  color: #ffffff;\n  font-weight: 600;\n  letter-spacing: 1px;\n  margin-top: 10px;\n  margin-left: 5px;\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"batchCardOuter": `BatchCard_batchCardOuter__7gdQY`,
	"container": `BatchCard_container__9aKp7`,
	"badge": `BatchCard_badge__RBfOz`,
	"cardText": `BatchCard_cardText__T0y-n`,
	"badgeText": `BatchCard_badgeText__AoXNn`,
	"tutorName": `BatchCard_tutorName__wQQxj`
};
export default ___CSS_LOADER_EXPORT___;
