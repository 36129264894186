import axios from "axios";
import{applicationActions} from "../Data/Slices/ApplicationSlice";
const url = "http://qa.edvanz.com";
export const handleFileChange = async (file,dispatch) => {
    const formData = new FormData();
    formData.append('attachment', file);
    formData.append('user_id', 4);
    try {
        let { data } = await axios.post(
            `${url}/api/mobile/user/upload-attachments`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })

        return data;
    } catch (error) {
        // console.log(error)
        alert("check your internet connection !!");
        dispatch(
            applicationActions.setAlertData({
              isAlert: true,
              alertMessage: "Internal Server Error",
              alertType: "danger",
            })
          );
          setTimeout(() => {
            dispatch(
              applicationActions.setAlertData({
                isAlert: false,
                alertMessage: "",
                alertType: "",
              })
            );
          }, 3000);
    }
};
