import React from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const Logout = ({ onLogout }) => {
  const navigate = useNavigate();

  const cookies = new Cookies()

  const handleLogout = async () => {
    try {
      localStorage.removeItem('token');
      navigate('/login');
      if (typeof onLogout === 'function') {
        onLogout();
      }
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <div>
      <button onClick={handleLogout}>Logout</button>
    </div>
  );
};

export default Logout;
