// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-wrapper {
    position: relative;
  }
  .truncated-text {
    cursor: pointer;
  }
  .custom-tooltip {
    position: absolute;
    top: 100%;
    transform: translateY(-50%);
    background-color: #333;
    color: #fff;
    padding: 8px;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1;
    font-size: 0.875rem;
    margin-top: 8px;
    width: auto;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .tooltip-wrapper:hover .custom-tooltip {
    opacity: 1;
  }
  `, "",{"version":3,"sources":["webpack://./src/Components/Pages/Session/Tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;EACA;IACE,eAAe;EACjB;EACA;IACE,kBAAkB;IAClB,SAAS;IACT,2BAA2B;IAC3B,sBAAsB;IACtB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,0CAA0C;IAC1C,UAAU;IACV,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,6BAA6B;EAC/B;;EAEA;IACE,UAAU;EACZ","sourcesContent":[".tooltip-wrapper {\n    position: relative;\n  }\n  .truncated-text {\n    cursor: pointer;\n  }\n  .custom-tooltip {\n    position: absolute;\n    top: 100%;\n    transform: translateY(-50%);\n    background-color: #333;\n    color: #fff;\n    padding: 8px;\n    border-radius: 5px;\n    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);\n    z-index: 1;\n    font-size: 0.875rem;\n    margin-top: 8px;\n    width: auto;\n    text-align: center;\n    opacity: 0;\n    transition: opacity 0.3s ease;\n  }\n  \n  .tooltip-wrapper:hover .custom-tooltip {\n    opacity: 1;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
