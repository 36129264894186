import React from "react";
import { useSelector } from "react-redux";

const TestSubmision = () => {
  const answerState = useSelector((state) => state.answerData.answer);
  const ReportCard = ({
    questionId,
    Position,
    QuestionState,
    answer,
    AttemptTime,
  }) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#F2F3F7",
          padding: "10px",
          borderRadius: "10px",
          border: "0.3px solid #e6e6e6",
          margin: "10px 0px",
        }}
      >
        <div>
          <h5>QuestionId</h5>
          <p>{questionId}</p>
        </div>
        <div>
          <h5>Position</h5>
          <p>{Position}</p>
        </div>
        <div>
          <h5>Question_state</h5>
          <p>{QuestionState}</p>
        </div>
        <div>
          <h5>Answer</h5>
          <p>{answer}</p>
        </div>
        <div>
          <h5>Attempt_Time</h5>
          <p>{AttemptTime} Seconds</p>
        </div>
      </div>
    );
  };
  return (
    <div style={{ padding: "10px" }}>
      <h1 style={{ textAlign: "center" }}>Test Submision</h1>
      {answerState?.map((answer) => (
        <ReportCard
          key={answer.questionId}
          questionId={answer.questionId}
          Position={answer.position}
          QuestionState={answer.question_state}
          answer={answer.answer}
          AttemptTime={answer.attempt_time}
        />
      ))}
    </div>
  );
};

export default TestSubmision;
