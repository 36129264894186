import React, { useCallback, useEffect, useState } from "react";
import "./Post.module.css";
import Chat from "../../UI/Chat/Chat";
import Comment from "../../UI/Comment/Comment";
import { getUserAllFeeds, handleCommentPost } from "../../../Store/API/User";
import Sidebar from "../Sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../UserInterface/Spinner/Spinner";

const Post = () => {
  const [feeds, setFeeds] = useState([]);
  const [fetchAgain, setFetchAgain] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch=useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);

  const token = userState?.token;
  const group_id = batchState?.groupId;
  const user_id = userState?.userId;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await getUserAllFeeds(token, group_id, user_id,dispatch);
        setFeeds(data.feeds);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    if (batchState.groupId) {
      fetchData();
    }
  }, [fetchAgain, batchState]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex h-[95vh] w-full">
      {/* <Sidebar /> */}
      <div className="flex md:w-[100%] mt-10 overflow-auto mx-auto w-full flex-col md:px-5 pl-2 pr-2 py-10 h-full bg-gray-100">
        <div className="chat">
          <Chat setFetchAgain={setFetchAgain} feed={feeds} />
        </div>
        <div className="comment py-3">
          {feeds.length === 0 && (
            <div className="text-center text-xl text-[#f3a041]">
              No Comments Found
            </div>
          )}
          {feeds?.map((feed) => (
            <Comment
              setFetchAgain={setFetchAgain}
              key={feed.activity_id}
              feed={feed}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Post;
