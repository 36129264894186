import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-left">Innovartan@2024</div>
      <div className="footer-right">
        <a href="https://innovartan.com/privacy-policy" className="footer-link">
          Privacy Policy
        </a>
        <a href="https://innovartan.com/term-of-use" className="footer-link">
          Terms and Conditions
        </a>
      </div>
    </div>
  );
};

export default Footer;
