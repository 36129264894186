// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TimeUpModal_modalOuterContainer__c9VA9 {
  background-color: #e1e4ff;
  border-radius: 8px;
}
.TimeUpModal_heading__mbd7R {
  text-align: center;
}
.TimeUpModal_timer__WFV9o {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
  color: red;
}

.TimeUpModal_button__KTJvz {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.TimeUpModal_buttonContainer__DkJ8q {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modals/TimeUpModal.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".modalOuterContainer {\n  background-color: #e1e4ff;\n  border-radius: 8px;\n}\n.heading {\n  text-align: center;\n}\n.timer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  margin-top: 20px;\n  color: red;\n}\n\n.button {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-self: center;\n}\n\n.buttonContainer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  align-self: center;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOuterContainer": `TimeUpModal_modalOuterContainer__c9VA9`,
	"heading": `TimeUpModal_heading__mbd7R`,
	"timer": `TimeUpModal_timer__WFV9o`,
	"button": `TimeUpModal_button__KTJvz`,
	"buttonContainer": `TimeUpModal_buttonContainer__DkJ8q`
};
export default ___CSS_LOADER_EXPORT___;
