import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import classes from "./TimeUpModal.module.css";
// import { Link } from "react-router-dom";

const TimeUpModal = ({ show, handleTestSubmit }) => {
  return (
    <div>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ backgroundColor: "rgba(0, 0, 0, 1)" }}
      >
        <Modal.Body className={classes.modalOuterContainer}>
          <div>
            <h3 className={classes.heading}>Time's Up!</h3>
            <p className={classes.timer}>
              Test has been automatically submitted by the system.
            </p>
            <div className={classes.buttonContainer}>
              {/* <a href="https://app.innovartan.com"> */}
              <Button className={classes.button} onClick={handleTestSubmit}>
                Close
              </Button>
              {/* </a> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TimeUpModal;
