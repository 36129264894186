import React from "react";
import { useTimer } from "react-timer-hook";
import classes from "../../TestScreen.module.css";
import { useDispatch, useSelector } from "react-redux";
import { submitQuiz } from "../../../Store/API/API";

const CustomTimer = ({ expiryTimestamp, callFunction, parentFunction, quizId }) => {
  const questionMendatory = useSelector((state) => state.answerData.mendatory);
  const answerState = useSelector((state) => state.answerData.answer);
  const userAttemptId = useSelector((state) => state.answerData.attemptId);
  const userState = useSelector((state) => state.userData.userData);
  const dispatch=useDispatch();
  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp,
    onExpire: async () => {
      if (callFunction) {
        if (userAttemptId) {
          // eslint-disable-next-line
          const data = await submitQuiz(
            userState,
            userAttemptId,
            answerState,
            questionMendatory,
            quizId || userState?.testId,dispatch
          );

          // console.log(data, "data");
        }
        parentFunction();
      }
    },
  });

  return (
    <div className={classes.time}>
      <span className={classes.TimeLeft}>{hours}</span>:
      <span className={classes.TimeLeft}>{minutes}</span>:
      <span className={classes.TimeLeft}>{seconds}</span>
    </div>
  );
};

export default CustomTimer;
