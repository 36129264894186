import React from "react";
import styles from "./TaxInvoice.module.css";

const taxInvoice = () => {
  return (
    <div className={styles.TIouter}>
      <div className={styles.invoiceBox}>
        <table cellPadding="0" cellSpacing="0">
          <tr className="top">
            <td colSpan="10">
              <table>
                <thead>
                  <td className={styles.bb}>
                    <p className={styles.images}>
                      <img
                        className={styles.innoLogo}
                        src="/images/qa.png"
                      />
                    </p>
                  </td>
                  <td className={styles.bb}>
                    <p className={styles.inNum}>
                      <strong>ORIGINAL FOR RECIPIENT</strong>
                    </p>
                    <h1 className={styles.h1}>TAX INVOICE</h1>
                    <p className={styles.inNum}>
                      <strong>Invoice #:</strong> ENV/2023-24/0001
                      <br />
                      <strong>Invoice date:</strong> 2024-06-08 19:14:06
                      <br />
                    </p>
                  </td>
                </thead>
                <tr>
                  <td width="50%">
                    <p className={styles.infoLeft}>
                      <strong>Supplier details:</strong>
                    </p>
                    <p className={styles.inNumLeft}>
                      INNOVARTAN LEARNING SOLUTIONS PRIVATE LIMITED
                      <br />
                      8th Floor, Riana,
                      <br />
                      Perito, Plot No.- 59,
                      <br />
                      Sector 136, Noida, 201301
                      <br />
                      GSTIN: 09AAHCI1206L1ZK
                      <br />
                      PAN no. AAHCI206L
                    </p>
                  </td>
                  <td width="60%">
                    <p className={styles.infoRight}>
                      <strong>Recipient details:</strong>
                    </p>
                    <p className={styles.inNumRight}>
                      Student Mahindara
                      <br />
                      student.mahindra@innovartan.com
                      <br />
                      0001112244
                      <br />
                      Noida
                      <br />
                      Other 1332323
                      <br />
                    </p>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr className={styles.heading}>
            <td style={{ maxWidth: "180px", textAlign: "left" }}>Course</td>
            <td style={{ textAlign: "right" }}></td>
            <td style={{ textAlign: "right" }}>Amount</td>
            <td style={{ textAlign: "center" }}>IGST (18%)</td>
            <td style={{ textAlign: "center" }}>Total Amount</td>
            <td style={{ textAlign: "center" }}>Paid Amount</td>
          </tr>
          <tr className={styles.item}>
            <td style={{ maxWidth: "180px" }}>Mahindra Physics</td>
            <td style={{ textAlign: "center" }}></td>
            <td style={{ textAlign: "center" }}>100</td>
            <td style={{ textAlign: "center" }}>18</td>
            <td style={{ textAlign: "center" }}>118</td>
            <td style={{ textAlign: "center" }}>118</td>
          </tr>
          <tr className={styles.heading}>
            <td style={{ maxWidth: "180px" }}>Total</td>
            <td style={{ textAlign: "center" }}></td>
            <td style={{ textAlign: "center" }}>100</td>
            <td style={{ textAlign: "center" }}>18</td>
            <td style={{ textAlign: "center" }}>118</td>
            <td style={{ textAlign: "center" }}>118</td>
          </tr>
          <tr>
            <td colSpan="8" align="right" className={styles.sigContainer}>
              <p style={{ textAlign: "left" }}>
                <em>
                  This is a system generated invoice and does not require a
                  signature or a digital signature
                </em>
              </p>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default taxInvoice;
