import React from "react";
import classes from "./BatchCard.module.css";
import { useDispatch } from "react-redux";
import { applicationActions } from "../../../../Store/Data/Slices/ApplicationSlice";

const BatchCrad = ({ batch }) => {
  const dispatch = useDispatch();

  const handleBatchClick = () => {
    const batchData = {
      groupId: batch.group_id,
      batchName: batch.group_name,
      batchColor: batch.color_code,
    };
    // console.log(batchData);
    dispatch(applicationActions.setBatchData(batchData));
    localStorage.setItem("batchData", JSON.stringify(batchData));

    localStorage.setItem("curr_directory", JSON.stringify([]));
  };

  return (
    <div
      className={classes.batchCardOuter}
      style={{ backgroundColor: batch?.color_code }}
      onClick={handleBatchClick}
    >
      <h4 className={classes.cardText}>{batch.group_name}</h4>
      <div className={classes.badge}>
        <p className={classes.badgeText}>
          {batch?.batch_type.charAt(0).toUpperCase() +
            batch?.batch_type.slice(1)}
        </p>
      </div>
      <p className={classes.tutorName}>By: {"Innovartan"}</p>
    </div>
  );
};

export default BatchCrad;
