import React from 'react'
import "./CardView.css";
const CardView = ({ data, renderContent,noDataMessage  }) => {
  if (data=== undefined){
    return(
      <>
        <div className="text-center text-2xl text-[#F3A041] mobile-view empty">
            {noDataMessage}
          </div>
      </>
    )
  }
    return (
        <div>
        {data.length === 0 ? (
          <div className="text-center text-2xl text-[#F3A041] mobile-view empty">
            {noDataMessage}
          </div>
        ) : (
          data.map((item, idx) => (
            <div className="mobile-view" key={idx}>
              {renderContent(item)}
            </div>
          ))
        )}
      </div>
      );
}
export default CardView;