import React, { useState, useEffect } from "react";
import "./Report.css";

const ReportCard = () => {
  return (
    <div className="flex h-[100vh] w-[100%] overflow-auto">
      <div className="flex md:w-[100%] w-full flex-col px-10 py-10 h-full bg-gray-100 mt-10 ">
        <div className="student-feedback">
          <div className="form-row">
            <div className="form-group">
              <h2>Report Card</h2>
            </div>
          </div>
          <div className="student-profile">
            <section className="profile-header">
              <div className="form-row">
                <div className="form-group">
                  <h2>Student Profile</h2>
                  <div className="profile-details">
                    <div>
                      <strong>Name:</strong> Name Surname
                    </div>
                    <div>
                      <strong>Year:</strong> 2024
                    </div>
                    <div>
                      <strong>Class:</strong> XII
                    </div>
                    <div>
                      <strong>Examination Type:</strong> Mock Test
                    </div>
                    <div>
                      <strong>Subjects Enrolled:</strong> Physics, Chemistry,
                      Maths
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="overview">
              <h2>Overview</h2>
              <div className="overview-cards">
                <div className="card">
                  <div className="icon">📄</div>
                  <div className="details">
                    <h3>Grade Achieved</h3>
                    <p>A</p>
                  </div>
                </div>
                <div className="card">
                  <div className="icon">📊</div>
                  <div className="details">
                    <h3>Performance</h3>
                    <p>Average</p>
                  </div>
                </div>
                <div className="card">
                  <div className="icon">🏆</div>
                  <div className="details">
                    <h3>Candidate Rank</h3>
                    <p>03</p>
                  </div>
                </div>
                <div className="card">
                  <div className="icon">📈</div>
                  <div className="details">
                    <h3>Average Percentile</h3>
                    <p>82%</p>
                  </div>
                </div>
              </div>
            </section>

            <section className="test-details">
              <h2>Test Details</h2>
              <div className="test-info">
                <div className="form-group">
                  <label>Test Name</label>
                  <input type="text" value="Physics Test" readOnly />
                </div>
                <div className="form-group">
                  <label>Appeared on</label>
                  <input type="text" value="4-05-24" readOnly />
                </div>
                <div className="form-group">
                  <label>Total Duration</label>
                  <input type="text" value="120 Mins" readOnly />
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <input type="email" value="namesurname@gmail.com" readOnly />
                </div>
                <div className="form-group">
                  <label>Assessment Type</label>
                  <select>
                    <option>Please Select</option>
                    {/* Add more options if needed */}
                  </select>
                </div>
              </div>
              <div className="test-table">
                <table>
                  <thead>
                    <tr>
                      <th>Test Name</th>
                      <th>Test Duration</th>
                      <th>Scored</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Physics</td>
                      <td>20 min</td>
                      <td>75%</td>
                    </tr>
                    <tr>
                      <td>Chemistry</td>
                      <td>20 min</td>
                      <td>60%</td>
                    </tr>
                    <tr>
                      <td>Maths</td>
                      <td>20 min</td>
                      <td>70%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section className="chapter-analysis">
              <h2>Chapter wise Analysis</h2>
              <div className="chapter-filter">
                <div className="form-group">
                  <label>Select Subject</label>
                  <select>
                    <option>Please Select</option>
                    {/* Add more options if needed */}
                  </select>
                </div>
                <div className="form-group">
                  <label>Select Chapter</label>
                  <select>
                    <option>Please Select</option>
                    {/* Add more options if needed */}
                  </select>
                </div>
                <button className="analysis-button">Detailed Analysis</button>
              </div>
              <div className="chapter-table">
                <table>
                  <thead>
                    <tr>
                      <th>Chapter Name</th>
                      <th>No. of Questions</th>
                      <th>Scored</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Chapter 1</td>
                      <td>20</td>
                      <td>75%</td>
                    </tr>
                    <tr>
                      <td>Chapter 2</td>
                      <td>20</td>
                      <td>60%</td>
                    </tr>
                    <tr>
                      <td>Chapter 3</td>
                      <td>20</td>
                      <td>70%</td>
                    </tr>
                    <tr>
                      <td>Chapter 4</td>
                      <td>20</td>
                      <td>83%</td>
                    </tr>
                    <tr>
                      <td>Chapter 5</td>
                      <td>20</td>
                      <td>83%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section className="overall-performance">
              <h2>Overall Performance</h2>
              <div className="performance-details">
                <div className="pass-rate">
                  <h3>Pass Rate</h3>
                  <p>Exceeds Expectations</p>
                  <p className="star">⭐⭐</p>
                </div>
                <div className="performance-chart">
                  <p>78.6%</p>
                </div>
              </div>
            </section>

            <section className="performance-statistics">
              <h2>Performance Statistics</h2>
              <div className="statistics-cards">
                <div className="card">
                  <p>80%</p>
                  <p>Current Percentage</p>
                </div>
                <div className="card">
                  <p>90%</p>
                  <p>Target Percentage</p>
                </div>
                <div className="card">
                  <p>A</p>
                  <p>Grade Achieved</p>
                </div>
              </div>
            </section>

            <section className="academic-subjects">
              <h2>Academic Subjects</h2>
              <div className="subjects-cards">
                <div className="card">
                  <p>91%</p>
                  <p>Physics</p>
                </div>
                <div className="card">
                  <p>91%</p>
                  <p>Chemistry</p>
                </div>
                <div className="card">
                  <p>91%</p>
                  <p>Maths</p>
                </div>
              </div>
            </section>

            <section className="overall-summary">
              <h2>Overall Summary</h2>
              <div className="summary-content">
                <p>
                  <strong>{`[Student Name]`}</strong> has shown consistent
                  understanding and application of concepts in Physics,
                  Chemistry, and Mathematics. Excelling in problem-solving,
                  their performance reflects a solid grasp of each subject,
                  setting a high standard for academic achievement.
                </p>
                <h3>Strengths</h3>
                <p>
                  <strong>{`[Student Name]`}</strong> received areas of
                  improvement in time management across Physics, Chemistry, and
                  Mathematics, highlighting the need for further practice to
                  finish the problems during exams.
                </p>
                <h3>Weaknesses</h3>
                <p>
                  Physics: <strong>{`[Student Name]`}</strong> must focus on
                  practicing concepts, particularly in topics involving
                  theoretical frameworks and their application in real-world
                  scenarios.
                </p>
                <p>
                  Chemistry: <strong>{`[Student Name]`}</strong> should
                  concentrate on understanding the underlying principles and
                  methodologies, especially in areas of organic chemistry and
                  problem-solving strategies.
                </p>
                <p>
                  Mathematics: <strong>{`[Student Name]`}</strong> could focus
                  on strengthening their grasp of advanced mathematical
                  concepts, particularly in problem-solving under timed
                  conditions, to achieve higher accuracy and efficiency in
                  exams.
                </p>
              </div>
              <button className="download-button">Download PDF</button>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportCard;
