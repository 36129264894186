// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestSearchTable_STOContainer__zhrzL {
  background-color: #002337;
  padding: 20px;
  color: white;
}

.TestSearchTable_STOinnerContainer__zDfeP {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.TestSearchTable_inputFiled__0kUnA {
  background-color: #1c3a4b;
  border-radius: 5px;
  align-items: center;
  /* width: 100px; */
}
`, "",{"version":3,"sources":["webpack://./src/Components/UserInterface/TestList/TestSearchTable.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".STOContainer {\n  background-color: #002337;\n  padding: 20px;\n  color: white;\n}\n\n.STOinnerContainer {\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.inputFiled {\n  background-color: #1c3a4b;\n  border-radius: 5px;\n  align-items: center;\n  /* width: 100px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"STOContainer": `TestSearchTable_STOContainer__zhrzL`,
	"STOinnerContainer": `TestSearchTable_STOinnerContainer__zDfeP`,
	"inputFiled": `TestSearchTable_inputFiled__0kUnA`
};
export default ___CSS_LOADER_EXPORT___;
