// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-view {
  display: none;
}
.mobile-view .empty {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-view .btn {
  margin-left: 10px;
  width: 5rem;
  background-color: royalblue;
  color: rgba(255, 255, 255, 0.829);
  border-radius: 6px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  padding: 3px 0px;
}
@media (max-width: 500px) {
  .main-container {
    display: none;
  }
  .mobile-view {
    display: block;
    width: 100%;
    background-color: white;
    border-top: 3px solid orange;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 20px;
    padding: 1rem;
  }
  .mobile-view strong {
    color: #484747;
    font-size: 1rem;
  }
  .mobile-view .icons {
    display: flex;
    margin-top: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Session/CardView.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,2BAA2B;EAC3B,iCAAiC;EACjC,kBAAkB;EAClB,4EAA4E;EAC5E,gBAAgB;AAClB;AACA;EACE;IACE,aAAa;EACf;EACA;IACE,cAAc;IACd,WAAW;IACX,uBAAuB;IACvB,4BAA4B;IAC5B,mBAAmB;IACnB,4EAA4E;IAC5E,mBAAmB;IACnB,aAAa;EACf;EACA;IACE,cAAc;IACd,eAAe;EACjB;EACA;IACE,aAAa;IACb,eAAe;EACjB;AACF","sourcesContent":[".mobile-view {\n  display: none;\n}\n.mobile-view .empty {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.mobile-view .btn {\n  margin-left: 10px;\n  width: 5rem;\n  background-color: royalblue;\n  color: rgba(255, 255, 255, 0.829);\n  border-radius: 6px;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);\n  padding: 3px 0px;\n}\n@media (max-width: 500px) {\n  .main-container {\n    display: none;\n  }\n  .mobile-view {\n    display: block;\n    width: 100%;\n    background-color: white;\n    border-top: 3px solid orange;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);\n    margin-bottom: 20px;\n    padding: 1rem;\n  }\n  .mobile-view strong {\n    color: #484747;\n    font-size: 1rem;\n  }\n  .mobile-view .icons {\n    display: flex;\n    margin-top: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
