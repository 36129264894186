// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SubmiteModal_modalOuterContainer__eS5wl {
  background-color: #e1e4ff;
  border-radius: 8px;
}
.SubmiteModal_heading__FYiro {
  text-align: center;
}
.SubmiteModal_timer__klY4q {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  color: red;
}

.SubmiteModal_detailsModal__eHq8g {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.SubmiteModal_typeBox__-91Lz {
  width: 170px;
  margin-bottom: 10px;
  padding: 5px 0px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.SubmiteModal_valueBox__Wqdbb {
  width: 50px;
  padding: 5px 0px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  border-radius: 5px;
}

.SubmiteModal_modalButton__ps8mW {
  margin-right: 20px;
  padding: 10px 15px !important;
  display: flex;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modals/SubmiteModal/SubmiteModal.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,aAAa;EACb,uBAAuB;AACzB","sourcesContent":[".modalOuterContainer {\n  background-color: #e1e4ff;\n  border-radius: 8px;\n}\n.heading {\n  text-align: center;\n}\n.timer {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n  color: red;\n}\n\n.detailsModal {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n\n.typeBox {\n  width: 170px;\n  margin-bottom: 10px;\n  padding: 5px 0px;\n  border-radius: 5px;\n  display: flex;\n  justify-content: center;\n}\n\n.valueBox {\n  width: 50px;\n  padding: 5px 0px;\n  margin-bottom: 10px;\n  display: flex;\n  justify-content: center;\n  border-radius: 5px;\n}\n\n.modalButton {\n  margin-right: 20px;\n  padding: 10px 15px !important;\n  display: flex;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalOuterContainer": `SubmiteModal_modalOuterContainer__eS5wl`,
	"heading": `SubmiteModal_heading__FYiro`,
	"timer": `SubmiteModal_timer__klY4q`,
	"detailsModal": `SubmiteModal_detailsModal__eHq8g`,
	"typeBox": `SubmiteModal_typeBox__-91Lz`,
	"valueBox": `SubmiteModal_valueBox__Wqdbb`,
	"modalButton": `SubmiteModal_modalButton__ps8mW`
};
export default ___CSS_LOADER_EXPORT___;
