import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import classes from "./Batch.module.css";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import BatchCard from "./BatchCard/BatchCard";
import { getBtaches } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../UserInterface/Spinner/Spinner";

const Batches = () => {
  const userState = useSelector((state) => state.userData.userData);
  const dispatch=useDispatch();
  const [year, setYear] = useState("");
  const [subject, setSubject] = useState("");
  const [batches, setBatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const fetchBatches = async () => {
    const data = await getBtaches(userState.token, userState.userId,dispatch);
    if (data) {
      // console.log(data);
      setBatches(data);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchBatches();
  }, []);

  if (isLoading) {
    return (
      <div className="flex h-[100vh] w-[100%] overflow-y-auto overflow-x-hidden">
        <div className={classes.batchesContainer}>
          <div className={classes.sectionOuter}>
            <div>
              <i className="fa-solid fa-book-open"></i>
              <span style={{ marginLeft: "10px" }}>Batches</span>
            </div>
          </div>
          <div className={classes.horizontalLine}></div>
          <div className={classes.batchContainer}>
            <div className={classes.dropdownContainer}>
              <div style={{ margin: "10px", marginRight: "50px" }}>
                <p className={classes.dropdownHeading}>
                  Select your Academic Year
                </p>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>Select Academic Year</InputLabel>
                  <Select
                    value={year}
                    onChange={handleYearChange}
                    label="Select Year"
                  >
                    <MenuItem value={2019}>Academic Year 2019-2020</MenuItem>
                    <MenuItem value={2020}>Academic Year 2020-2021</MenuItem>
                    <MenuItem value={2021}>Academic Year 2021-2022</MenuItem>
                    <MenuItem value={2022}>Academic Year 2022-2023</MenuItem>
                    <MenuItem value={2023}>Academic Year 2023-2024</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/* <div style={{ margin: "10px", marginRight: "50px" }}>
                <p className={classes.dropdownHeading}>Choose your Subject</p>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel>Select Your Subject</InputLabel>
                  <Select
                    value={subject}
                    onChange={handleSubjectChange}
                    label="Select Subject"
                  >
                    <MenuItem value={"Mathematics"}>Mathematics</MenuItem>
                    <MenuItem value={"Physics"}>Physics</MenuItem>
                    <MenuItem value={"Chemistry"}>Chemistry</MenuItem>
                    <MenuItem value={"Biology"}>Biology</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
            </div>
          </div>
          <div className={classes.horizontalLine}></div>
          <div className={classes.loader}>
            <Spinner />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex h-[100vh] w-[100%] overflow-y-auto overflow-x-hidden">
      <div className={classes.batchesContainer}>
        <div className={classes.sectionOuter}>
          <div>
            <i className="fa-solid fa-book-open"></i>
            <span style={{ marginLeft: "10px" }}>Batches</span>
          </div>
        </div>
        <div className={classes.horizontalLine}></div>
        <div className={classes.batchContainer}>
          <div className={classes.dropdownContainer}>
            <div style={{ margin: "10px", marginRight: "50px" }}>
              <p className={classes.dropdownHeading}>
                Select your Academic Year
              </p>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Select Academic Year</InputLabel>
                <Select
                  value={year}
                  onChange={handleYearChange}
                  label="Select Year"
                >
                  <MenuItem value={2019}>Academic Year 2019-2020</MenuItem>
                  <MenuItem value={2020}>Academic Year 2020-2021</MenuItem>
                  <MenuItem value={2021}>Academic Year 2021-2022</MenuItem>
                  <MenuItem value={2022}>Academic Year 2022-2023</MenuItem>
                  <MenuItem value={2023}>Academic Year 2023-2024</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* <div style={{ margin: "10px", marginRight: "50px" }}>
              <p className={classes.dropdownHeading}>Choose your Subject</p>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel>Select Your Subject</InputLabel>
                <Select
                  value={subject}
                  onChange={handleSubjectChange}
                  label="Select Subject"
                >
                  <MenuItem value={"Mathematics"}>Mathematics</MenuItem>
                  <MenuItem value={"Physics"}>Physics</MenuItem>
                  <MenuItem value={"Chemistry"}>Chemistry</MenuItem>
                  <MenuItem value={"Biology"}>Biology</MenuItem>
                  <MenuItem value={"Other"}>Other</MenuItem>
                </Select>
              </FormControl>
            </div> */}
          </div>
        </div>
        <div className={classes.horizontalLine}></div>
        <div className={classes.cardContainer}>
          {batches?.length === 0 && (
            <div className={classes.noData}>
              <h5>No Batches Found</h5>
            </div>
          )}
          {batches?.map((batch, index) => (
            <BatchCard key={index} batch={batch} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Batches;
