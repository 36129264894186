// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../public/loader.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Spinner_spinner__kZgMi {
  position: fixed;
  z-index: 99999999;
  display: flex;
  /* background: rgba(0, 0, 0, 0.1); */
  width: 100vw;
  height: 100vh;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -65px;
}

@media (max-width: 768px) {
  .Spinner_spinner__kZgMi {
    margin-left: -35px;
  }
}

@media (max-width: 576px) {
  .Spinner_spinner__kZgMi {
    margin-left: -15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/UserInterface/Spinner/Spinner.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,oCAAoC;EACpC,YAAY;EACZ,aAAa;EACb,yDAAsD;EACtD,4BAA4B;EAC5B,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".spinner {\n  position: fixed;\n  z-index: 99999999;\n  display: flex;\n  /* background: rgba(0, 0, 0, 0.1); */\n  width: 100vw;\n  height: 100vh;\n  background-image: url(\"../../../../public/loader.svg\");\n  background-repeat: no-repeat;\n  background-position: center;\n  margin-left: -65px;\n}\n\n@media (max-width: 768px) {\n  .spinner {\n    margin-left: -35px;\n  }\n}\n\n@media (max-width: 576px) {\n  .spinner {\n    margin-left: -15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spinner": `Spinner_spinner__kZgMi`
};
export default ___CSS_LOADER_EXPORT___;
