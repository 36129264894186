import React, { useState, useEffect } from "react";
import "./Payment.css";
import { getPayments } from "../../../Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Spinner from "../../UserInterface/Spinner/Spinner";
import CardView from "../Session/CardView";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const PaymentsHistory = () => {
  const navigate = useNavigate();
  const userState = useSelector((state) => state.userData.userData);
  const dispatch=useDispatch();
  const [paymentsData, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPayments = async () => {
    const data = await getPayments(userState.token, userState.userId,dispatch);
    console.log(data);
    if(data===undefined){
      setIsLoading(false);
      return
    }
    else if (data.paid) {
      setPayments(data?.paid);
      // console.log(paymentsData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchPayments();
  }, []);

  const handleInvoice = (payment) => {
    navigate("/taxInvoice");
  };

  const truncateRemarks = (remarks, wordLimit) => {
    const words = remarks;
    // console.log(words, words.length, wordLimit);
    if (words.length > wordLimit) {
      return {
        truncated: words.slice(0, wordLimit) + "...",
        full: remarks,
      };
    }
    return {
      truncated: remarks,
      full: remarks,
    };
  };

  const paymentData = (paymentsData) => (
    <>
      <div>
        <strong> Date:</strong> <span>{paymentsData.payment_date}</span>
      </div>
      <div>
        <strong>Batch:</strong> <span>{paymentsData.batch_name}</span>
      </div>
      <div>
        <strong>Amount:</strong> <span>{paymentsData.amount}</span>
      </div>
      <div>
        <strong>Payment Method:</strong>
        <span>{paymentsData.mode}</span>
      </div>
      <div>
        <strong>Status:</strong>{" "}
        <span
          className={
            paymentsData.status === "paid" ? "status-paid" : "status-pending"
          }
        >
          {paymentsData?.status.charAt(0).toUpperCase() +
            paymentsData?.status.slice(1)}
        </span>
      </div>
      <div>
        <strong>Remark:</strong>{" "}
        <span className="tooltip">
          {/* {paymentsData.remarks.length > 50 ? paymentsData.remarks.slice(0, 50) + '...' : paymentsData.remarks } */}
        </span>
      </div>
      <div>
        <strong>Action:</strong>{" "}
        <span>
          <button className="invoice-button" onClick={handleInvoice}>
            Invoice
          </button>
        </span>
      </div>
    </>
  );
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex h-[100vh] w-[100%]">
      <div
        className="flex md:w-[100%] w-full flex-col px-1 h-full overflow-auto bg-gray-100"
        style={{ paddingTop: "80px", paddingBottom: "50px" }}
      >
        <div
          className="edit-profile main-container"
          style={{ padding: "20px 15px 20px 15px" }}
        >
          <div className="form-group">
            <h2 style={{ textAlign: "center" }} b>
              Payment History
            </h2>
          </div>
          <div
            className="overflow-y-auto max-h-[75vh]"
            style={{ paddingBottom: "20px" }}
          >
            <Table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <Thead>
                <Tr className="bg-[#0A3C87] text-white uppercase text-sm leading-normal">
                  <Th className="py-2 px-0 text-center">Date</Th>
                  <Th className="py-2 px-0 text-center">Batch</Th>
                  <Th className="py-2 px-0 text-center">Amount</Th>
                  <Th className="py-2 px-0 text-center">Payment Method</Th>
                  <Th className="py-2 px-0 text-center">Status</Th>
                  <Th className="py-2 px-0 text-center">Remarks</Th>
                  <Th className="py-2 px-0 text-center">Actions</Th>
                </Tr>
              </Thead>
              <Tbody className="text-gray-600 text-sm font-light">
                {paymentsData?.length === 0 && (
                  <Tr>
                    <Td
                      colSpan="7"
                      className="py-2 px-6 text-center text-orange-500 font-bold"
                    >
                      No Payments Found
                    </Td>
                  </Tr>
                )}

                {paymentsData?.map((payment, index) => (
                  <Tr
                    className="border-b border-gray-200 hover:bg-gray-100"
                    key={index}
                  >
                    <Td className="py-2 px-0 text-center">
                      {payment.payment_date}
                    </Td>
                    <Td className="py-2 px-0 text-center">
                      {payment.batch_name}
                    </Td>
                    <Td className="py-2 px-0 text-center">{payment.amount}</Td>
                    <Td className="py-2 px-0 text-center">{payment.mode}</Td>
                    <Td
                      className={`py-2 px-0 text-center ${
                        payment.status === "paid"
                          ? "text-green-500"
                          : "text-red-500"
                      }`}
                    >
                      {payment.status.charAt(0).toUpperCase() +
                        payment.status.slice(1)}
                    </Td>
                    <Td className="py-2 px-0 text-center">
                      <div className="relative">
                        {
                          truncateRemarks(payment.remarks || "No Remarks", 15)
                            .truncated
                        }
                        {truncateRemarks(payment.remarks || "No Remarks", 15)
                          .full.length > 15 && (
                          <div className="absolute bg-gray-700 text-white text-xs rounded-lg p-2">
                            {truncateRemarks(payment.remarks, 15).full}
                          </div>
                        )}
                      </div>
                    </Td>
                    <Td className="py-3 px-6 text-center">
                      <button
                        onClick={handleInvoice}
                        className="bg-blue-500 text-white rounded px-3 py-1 transition duration-200 ease-in-out hover:bg-blue-600"
                      >
                        Invoice
                      </button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        </div>
        <h2 className="small" style={{ textAlign: "center" }}>
          Payment History
        </h2>
        <div style={{ padding: "0px 10px" }}>
          <CardView
            data={paymentsData}
            renderContent={paymentData}
            noDataMessage={"No Payments Found"}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentsHistory;
