import React from "react";
import classes from "./TestInfoCard.module.css";

const TestInfoCard = ({ heading, text }) => {
  return (
    <div className={classes.TestInfoCardOuterContainer}>
      <p className={classes.testCardHeading}>{heading}</p>
      <p className={classes.testCardText}>{text}</p>
    </div>
  );
};

export default TestInfoCard;
