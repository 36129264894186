import { configureStore } from "@reduxjs/toolkit";
import userDataSlice from "../Slices/UserDataSlice";
import answerSlice from "../Slices/AnswerSlice";
import questionSlice from "../Slices/QuestionSlice";
import applicationSlice from "../Slices/ApplicationSlice";

const reduxStore = configureStore({
  reducer: {
    userData: userDataSlice.reducer,
    answerData: answerSlice.reducer,
    questionData: questionSlice.reducer,
    applicationData: applicationSlice.reducer,
  },
});

export default reduxStore;
