import React, { useEffect, useState } from "react";
import DropDown from "../../UI/DropDown/DropDown";
import { useNavigate } from "react-router-dom";
import { fetchAssignments } from "../../../Store/API/User";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../UserInterface/Spinner/Spinner";
import CardView from "../Session/CardView";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const BatchAssignments = () => {
  const [assignments, setAssignments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch=useDispatch();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);

  const token = userState?.token;
  const userId = userState?.userId;
  const groupId = batchState?.groupId;

  const colors = {
    completed: "bg-green-400",
    expired: "bg-red-400",
    upcoming: "bg-blue-400",
  };

  useEffect(() => {
    const getData = async () => {
      const data = await fetchAssignments(token, userId, groupId,dispatch);
      setAssignments(data);
      setIsLoading(false);
    };

    getData();
  }, [token, userId, groupId]);

  const handleViewDetails = (assignmentId) => {
    navigate(`/assignment/details`, { state: { assignmentId } });
  };

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const assighnmentData = (item) => (
    <>
      <div>
        <strong>Titel:</strong> <span>{item.title}</span>
      </div>
      <div>
        <strong>Due Date:</strong> <span>{item.due_date}</span>
      </div>
      <div>
        <strong>Subject:</strong> <span>{item.subjects[0]}</span>
        <span>
          {item.subjects.slice(1).map((subject, index) => (
            <div key={index}>| {subject} </div>
          ))}
        </span>
      </div>
      <div>
        <strong>Chapter:</strong>{" "}
        <div className="flex-1 text-gray-600 w-full lg:w-auto">
          {item.chapters.map((chapter, index) => (
            <div key={index}>{chapter} |</div>
          ))}
        </div>
      </div>
      <div>
        <strong>Action:</strong>
        <span className="btn">
          <button onClick={() => handleViewDetails(item.assignment_id)}>
            View
          </button>
        </span>
      </div>
    </>
  );
  return (
    <div className="h-[97vh] sm:h-[100vh] w-[100%] overflow-auto">
      <div className="dashboard-content flex md:w-[100%] sm:w-[95%] w-full flex-col md:px-10 md:py-10 px-2 py-10 h-full bg-gray-100 mt-10 md:items-center">
        <div className="mt-3">
          <h1 className="text-2xl font-semibold text-gray-800 text-center mb-4">
            Assignment's
          </h1>
        </div>
        <div className="flex flex-col p-3 bg-white rounded-lg border-t-2 border-t-orange-500 main-container">
          {/*  <table className="payments-table">
            <thead>
              <tr>
                <th>Title</th>
                <th className="text-right">Due date</th>
                <th className="text-center">Subject</th>
                <th className="text-right">Chapter</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
          </table>
          <div className="w-full flex justify-between flex-col mb-3">
            {assignments.length === 0 && (
              <div className="text-center text-xl text-[#f3a041] mt-4">
                No Assignments Found
              </div>
            )}
            {assignments.map((item, idx) => (
              <div
                className={`w-full px-3 relative ${
                  idx % 2 !== 0 ? "bg-gray-100" : "bg-white"
                } flex flex-wrap lg:flex-nowrap items-center justify-between py-3`}
                key={item.assignment_id}
              >
                <div className="w-full lg:w-1/3 text-slate-700 font-semibold">
                  {item.title}
                </div>
                <div className="flex flex-wrap lg:flex-nowrap lg:my-2 w-full lg:w-1/3">
                  <div className="flex-1 text-gray-600 w-full lg:w-auto">
                    {item.due_date}
                  </div>
                  <div className="flex-1 text-gray-600 w-full lg:w-auto">
                    <span>{item.subjects[0]}</span>
                    <span>
                      {item.subjects.slice(1).map((subject, index) => (
                        <div key={index}>| {subject} </div>
                      ))}
                    </span>
                  </div>
                </div>
                <div className="flex ml-auto flex-wrap md:flex-nowrap lg:my-2 w-full lg:w-1/3">
                  <div className="flex-1 text-gray-600 w-full lg:w-auto">
                    {item.chapters.map((chapter, index) => (
                      <div key={index}>{chapter} |</div>
                    ))}
                  </div>
                  <button
                    onClick={() => handleViewDetails(item.assignment_id)}
                    className={`sm:w-auto h-[2rem] flex items-center justify-center text-gray-600 xl:w-[4rem] ml-auto absolute lg:static right-0 bottom-0 decoration-transparent ${colors.upcoming} lg:text-center lg:mx-auto text-xs text-white text-right px-2 py-1 rounded`}
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
          </div> */}

          <Table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <Thead>
              <Tr className="bg-[#0A3C87] text-white uppercase text-sm leading-normal">
                <Th className="py-3 px-6 text-center">Title</Th>
                <Th className="py-3 px-6 text-center">Due Date</Th>
                <Th className="py-3 px-6 text-center">Subject</Th>
                <Th className="py-3 px-6 text-center">Chapter</Th>
                <Th className="py-3 px-6 text-center">Actions</Th>
              </Tr>
            </Thead>
            <Tbody className="text-gray-600 text-sm font-light">
              {assignments.length === 0 && (
                <Tr>
                  <Td
                    colSpan="5"
                    className="py-3 px-6 text-center text-orange-500 font-bold"
                  >
                    No Assignments Found
                  </Td>
                </Tr>
              )}

              {assignments.map((item, idx) => (
                <Tr
                  className="border-b border-gray-200 hover:bg-gray-100"
                  key={item.assignment_id}
                >
                  <Td className="py-3 px-6 text-center font-bold ">
                    {item.title}
                  </Td>
                  <Td className="py-3 px-6 text-center"> {item.due_date}</Td>
                  <Td className="py-3 px-6 text-center">{item.subjects[0]}</Td>
                  <Td className="py-3 px-6 text-center">
                    {item.chapters.map((chapter, index) => (
                      <div key={index}>{chapter} |</div>
                    ))}
                  </Td>
                  <Td className="py-3 px-6 text-center">
                    <button
                      onClick={() => handleViewDetails(item.assignment_id)}
                      className={` text-white xl:w-[4rem] bg-blue-500 rounded px-3 py-1 transition duration-200 ease-in-out hover:bg-blue-600`}
                    >
                      View
                    </button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        <div className="mr-4 ml-4">
          <CardView
            data={assignments}
            renderContent={assighnmentData}
            noDataMessage="No Assignments Found"
          />
        </div>
      </div>
    </div>
  );
};

export default BatchAssignments;
