// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AIButton_chatButton__DeQpK {
  display: flex;
  align-items: center;
  background-color: #f0f4ff;
  border-radius: 25px;
  /* padding: 10px 15px; */
  padding-right: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
}

.AIButton_chatButton__DeQpK:hover {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}

.AIButton_iconWrapper__7ZwMM {
  background-color: #f7a05c;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.AIButton_icon__8gXRy {
  font-size: 20px;
}

.AIButton_text__fvoz1 {
  font-size: 14px;
  color: #6b7280;
  font-family: "Poppins", sans-serif;
}

.AIButton_aiIcon__4IO1E {
  width: 25px;
  height: 25px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/UI/AIButton/AIButton.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,wBAAwB;EACxB,mBAAmB;EACnB,uCAAuC;EACvC,eAAe;EACf,uCAAuC;AACzC;;AAEA;EACE,wCAAwC;AAC1C;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,YAAY;AACd","sourcesContent":[".chatButton {\n  display: flex;\n  align-items: center;\n  background-color: #f0f4ff;\n  border-radius: 25px;\n  /* padding: 10px 15px; */\n  padding-right: 15px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  transition: box-shadow 0.2s ease-in-out;\n}\n\n.chatButton:hover {\n  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);\n}\n\n.iconWrapper {\n  background-color: #f7a05c;\n  border-radius: 50%;\n  padding: 10px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-right: 10px;\n}\n\n.icon {\n  font-size: 20px;\n}\n\n.text {\n  font-size: 14px;\n  color: #6b7280;\n  font-family: \"Poppins\", sans-serif;\n}\n\n.aiIcon {\n  width: 25px;\n  height: 25px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatButton": `AIButton_chatButton__DeQpK`,
	"iconWrapper": `AIButton_iconWrapper__7ZwMM`,
	"icon": `AIButton_icon__8gXRy`,
	"text": `AIButton_text__fvoz1`,
	"aiIcon": `AIButton_aiIcon__4IO1E`
};
export default ___CSS_LOADER_EXPORT___;
