import React, { useEffect, useState } from "react";
import classes from "./CustomQuestion1.module.css";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { answerActions } from "../../../Store/Data/Slices/AnswerSlice";

const CustomQuestion = ({ currentQuestion }) =>
  // {
  //   // currentQuestion,
  //   // updateQuestionSet,
  //   // updateCurrentIndex,
  // }
  {
    const dispatch = useDispatch();
    const answerState = useSelector((state) => state.answerData.answer);

    // console.log(answerState, "answerState");

    const [selectedOption, setSelectedOption] = useState("");
    const [fibValues, setFibValues] = useState("");
    const [TFValue, setTFValue] = useState("");
    const [multipleChoice, setMultipleChoice] = useState([]);
    const [Intvalue, setIntValue] = useState("");

    const options = currentQuestion?.answer;

    // console.log(currentQuestion, "currentQuestion");

    // console.log(selectedSubjectQuestions, "selectedSubjectQuestions");
    // console.log(questionState, "questionState");

    useEffect(() => {
      // console.log(fibValues, "fibValues");
    }, [fibValues]);

    useEffect(() => {
      let tempAnswerObject = answerState.find(
        (item) => item.questionId === currentQuestion?.question_id
      );
      // console.log(tempAnswerObject, "tempAnswerObject");

      setSelectedOption(tempAnswerObject?.answer);
      setMultipleChoice(tempAnswerObject?.answer);
      setFibValues(tempAnswerObject?.answer);
      setIntValue(tempAnswerObject?.answer);
      setTFValue(tempAnswerObject?.answer);

      // eslint-disable-next-line
    }, [currentQuestion]);

    // useEffect(() => {
    //   if (currentQuestion.selectedAnswer === "") {
    //     let tempArray = [];
    //     for (let i = 0; i < currentQuestion?.fib_count; i++) {
    //       tempArray.push("");
    //     }
    //     setFibValues(tempArray);
    //   }
    //   if (currentQuestion.selectedAnswer !== "") {
    //     let tempArray = [];
    //     for (let i = 0; i < currentQuestion.fib_count; i++) {
    //       tempArray.push({
    //         id: i,
    //         value: currentQuestion.selectedAnswer[i].value,
    //       });
    //     }
    //     setFibValues(tempArray);
    //   }
    // }, [currentQuestion?.fib_count]);

    const handleUpdateAnswer = (value, notUpdate) => {
      dispatch(
        answerActions.updateAnswer({
          questionId: currentQuestion?.question_id,
          value: value,
        })
      );
      // console.log(currentQuestion?.answer, "currentQuestion");
      if (notUpdate === undefined) {
        const currentAnswer = answerState.find(
          (item) => item.questionId === currentQuestion?.question_id
        );
        if (currentQuestion?.section_subject) {
          if (currentAnswer?.answer === "") {
            console.log(
              currentQuestion?.section_subject,
              `section_${currentQuestion?.section}_mandetory`
            );
            dispatch(
              answerActions.updateMendatoryCount({
                subject: currentQuestion?.section_subject,
                section: `section_${currentQuestion?.section}_mandetory`,
              })
            );
          }
        }
      }
    };

    const handleOptionClick = (optionId) => {
      handleUpdateAnswer(optionId);
      setSelectedOption(optionId);
    };

    const handleFIBValueChange = (index, value, event) => {
      // let tempArray = [...fibValues]; // Create a copy of fibValues array
      // tempArray[index.index] = { ...tempArray[index.index], value }; // Update the value immutably
      // setFibValues(tempArray); // Update the state with the new array

      // // Assuming handleUpdateAnswer accepts an array of values and updates Redux state
      // handleUpdateAnswer(tempArray.map((item) => item.value));
      setFibValues(value);
      handleUpdateAnswer(value);
    };

    const handleMCQMAOptionClick = (optionId) => {
      let tempArray = [];
      if (multipleChoice?.includes(optionId)) {
        tempArray = multipleChoice.filter((item) => item !== optionId);
      } else {
        tempArray = [...multipleChoice, optionId];
      }
      handleUpdateAnswer(tempArray);
      setMultipleChoice(tempArray);
    };

    const handleINTTValueChange = (event) => {
      handleUpdateAnswer(event.target.value);
      setIntValue(event.target.value);
      // console.log(event.target.value, "event.target.value");
    };

    const handleTFValueChange = (value) => {
      handleUpdateAnswer(value);
      setTFValue(value);
    };

    const handleClearSelection = () => {
      handleUpdateAnswer("", true);
      setSelectedOption("");
      setFibValues([]);
      setTFValue("");
      setMultipleChoice([]);
      setIntValue("");
      dispatch(
        answerActions.updateQuestionState({
          questionId: currentQuestion?.question_id,
          value: "Not Answered",
        })
      );
      const currentAnswer = answerState.find(
        (item) => item.questionId === currentQuestion?.question_id
      );
      if (currentAnswer?.answer !== "") {
        dispatch(
          answerActions.reduceMendatoryCount({
            subject: currentQuestion?.section_subject,
            section: `section_${currentQuestion?.section}_mandetory`,
          })
        );
      }
    };

    const MCQOption = ({ option }) => {
      return (
        // <div></div>
        <div
          className={`${classes.scOptions} ${
            selectedOption === option.answer_value ? classes.selectedOption : ""
          }`}
          onClick={() => handleOptionClick(option.answer_value)}
        >
          <div
            className={
              selectedOption === option.answer_value
                ? classes.customRadioChecked
                : classes.customRadioUnchecked
            }
          >
            {option.answer_key}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: option.name,
            }}
            className={classes.optionText}
          ></div>
        </div>
      );
    };

    const FIBQption = (index) => {
      return (
        // <div></div>
        <div
          style={{
            marginRight: "20px",
            marginBottom: "10px",
          }}
        >
          <input
            type="text"
            className={classes.FIBInput}
            placeholder={`Enter your answer`}
            // defaultValue={
            //   fibValues[index.index] ? fibValues[index.index].value : ""
            // }
            defaultValue={fibValues}
            // value={fibValues[0]?.value}
            // onChange={() => {
            //   // Update the value in the component state immediately on change
            //   // const { value } = e.target;
            //   handleFIBValueChange();
            // }}
            onBlur={(e) => {
              // Update the value in the component state when the input field loses focus
              const { value } = e.target;
              handleFIBValueChange(index, value);
            }}
          />
        </div>
      );
    };

    const MCQMAOption = ({ option }) => {
      return (
        <div
          className={`${classes.scOptions} ${
            multipleChoice === option.answer_value ? classes.selectedOption : ""
          }`}
          onClick={() => handleMCQMAOptionClick(option.answer_value)}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: option.answer_key,
            }}
            className={
              multipleChoice?.includes(option.answer_value)
                ? classes.customRadioChecked
                : classes.customRadioUnchecked
            }
          >
            {/* {option.answer_key} */}
          </div>
          <p className={classes.optionText}>{option.name}</p>
        </div>
      );
    };

    const TFOption = () => {
      return (
        // <div></div>
        <div>
          <div className={classes.scOptions}>
            <div
              className={
                TFValue === "True"
                  ? classes.customRadioChecked
                  : classes.customRadioUnchecked
              }
              onClick={() => handleTFValueChange("True")}
            >
              A
            </div>
            <p className={classes.optionText}>True</p>
          </div>
          <div className={classes.scOptions}>
            <div
              className={
                TFValue === "False"
                  ? classes.customRadioChecked
                  : classes.customRadioUnchecked
              }
              onClick={() => handleTFValueChange("False")}
            >
              B
            </div>
            <p className={classes.optionText}>False</p>
          </div>
        </div>
      );
    };

    const INTTOption = () => {
      return (
        <div>
          <input
            type="number"
            // className={classes.FIBInput}
            placeholder="Enter your answer"
            defaultValue={Intvalue}
            onBlur={handleINTTValueChange}
          />
        </div>
      );
    };

    return (
      <div className={classes.scContainer}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          {currentQuestion && (
            <h5 className={classes.heading}>
              {currentQuestion?.section_subject ||
                currentQuestion?.question_subject ||
                "Common "}
              (Section
              {currentQuestion?.section?.charAt(0).toUpperCase() +
                currentQuestion?.section?.slice(1) || " (One)"}
              )
            </h5>
          )}
          <div className={classes.questionIdContainer}>
            <p
              className={classes.questionid}
            >{`ID :${currentQuestion?.question_id}`}</p>
            <Button onClick={handleClearSelection}>Clear Answer</Button>
          </div>
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html: currentQuestion?.question,
          }}
          className={classes.scQuestion}
        ></div>

        <div className={classes.optionContainer}>
          {currentQuestion?.type === "MCQ" && (
            <div>
              {options?.map((option, index) => (
                <MCQOption option={option} key={index} />
              ))}
            </div>
          )}
          {currentQuestion?.type === "FIB" && (
            <div className={classes.FIBOption}>
              {/* {Array.from({ length: currentQuestion.fib_count }).map(
                (_, index) => ( */}
              <FIBQption />
              {/* )
              )} */}
            </div>
          )}
          {currentQuestion?.type === "TF" && (
            <div className={classes.FIBOption}>
              <TFOption />
            </div>
          )}
          {currentQuestion?.type === "MCQMA" && (
            <div>
              {options?.map((option, index) => (
                <MCQMAOption option={option} key={index} />
              ))}
            </div>
          )}
          {currentQuestion?.type === "INTT" && (
            <div className={classes.FIBOption}>
              <INTTOption />
            </div>
          )}
        </div>
      </div>
    );
  };

export default CustomQuestion;
