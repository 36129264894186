// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #063181;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.footer-left {
  font-size: 14px;
  margin-left: 280px;
}

.footer-right {
  font-size: 14px;
}

.footer-link {
  margin-left: 20px;
  text-decoration: none;
  color: white;
}

.footer-link:hover {
  text-decoration: underline;
}

@media only screen and (max-width: 1024px) {
  .footer-left {
    margin-left: 200px;
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    padding: 10px;
  }

  .footer-left {
    margin-left: 150px;
  }

  .footer-link {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .footer {
    padding: 5px;
  }

  .footer-left,
  .footer-right {
    font-size: 10px;
    display: flex;
    flex-direction: column;
  }
  .footer-left {
    margin-left: 70px;
  }

  .footer-link {
    margin-left: 5px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Footer/Footer.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,SAAS;EACT,OAAO;EACP,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,qBAAqB;EACrB,YAAY;AACd;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,aAAa;EACf;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;;IAEE,eAAe;IACf,aAAa;IACb,sBAAsB;EACxB;EACA;IACE,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".footer {\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  background-color: #063181;\n  padding: 10px 20px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  color: white;\n}\n\n.footer-left {\n  font-size: 14px;\n  margin-left: 280px;\n}\n\n.footer-right {\n  font-size: 14px;\n}\n\n.footer-link {\n  margin-left: 20px;\n  text-decoration: none;\n  color: white;\n}\n\n.footer-link:hover {\n  text-decoration: underline;\n}\n\n@media only screen and (max-width: 1024px) {\n  .footer-left {\n    margin-left: 200px;\n  }\n}\n\n@media only screen and (max-width: 768px) {\n  .footer {\n    padding: 10px;\n  }\n\n  .footer-left {\n    margin-left: 150px;\n  }\n\n  .footer-link {\n    margin-left: 10px;\n  }\n}\n\n@media only screen and (max-width: 480px) {\n  .footer {\n    padding: 5px;\n  }\n\n  .footer-left,\n  .footer-right {\n    font-size: 10px;\n    display: flex;\n    flex-direction: column;\n  }\n  .footer-left {\n    margin-left: 70px;\n  }\n\n  .footer-link {\n    margin-left: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
