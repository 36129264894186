// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NoPage_notFoundContainer__T0jld {
  text-align: center;
  margin-top: 100px;
}

.NoPage_notFoundContainer__T0jld h1 {
  font-size: 48px;
  color: #333;
}

.NoPage_notFoundContainer__T0jld p {
  font-size: 20px;
  color: #666;
  margin-bottom: 20px;
}

.NoPage_notFoundContainer__T0jld a {
  text-decoration: none;
  color: #007bff;
  font-size: 18px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/NoPage/NoPage.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,eAAe;AACjB","sourcesContent":[".notFoundContainer {\n  text-align: center;\n  margin-top: 100px;\n}\n\n.notFoundContainer h1 {\n  font-size: 48px;\n  color: #333;\n}\n\n.notFoundContainer p {\n  font-size: 20px;\n  color: #666;\n  margin-bottom: 20px;\n}\n\n.notFoundContainer a {\n  text-decoration: none;\n  color: #007bff;\n  font-size: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFoundContainer": `NoPage_notFoundContainer__T0jld`
};
export default ___CSS_LOADER_EXPORT___;
