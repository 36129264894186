import React, { useState } from "react";
import styles from "./InnovartanAI.module.css";
import { generateText } from "./AICallling";

// Function to parse text and replace subscript/superscript markers with HTML tags
const parseChemicalFormula = (text) => {
  // Replace subscripts marked with {} (e.g., H{2}O -> H<sub>2</sub>O)
  text = text.replace(/{(\d+)}/g, "<sub>$1</sub>");
  // Replace superscripts marked with ^ (e.g., Na^+ -> Na<sup>+</sup>)
  text = text.replace(/\^([+-]?\d*)/g, "<sup>$1</sup>");
  return text;
};

const InnovartanAI = () => {
  const [textInput, setTextInput] = useState("");
  const [response, setResponse] = useState("");

  const handleGenerate = async () => {
    setResponse("");
    setTextInput("");
    await generateText(textInput, setResponse);
  };

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <h1>Streaming OpenAI For Innovartan</h1>
        <div className={styles.responseContainer}>
          {response.split("\n").map((line, index) => (
            <p
              key={index}
              className={styles.responseLine}
              dangerouslySetInnerHTML={{ __html: parseChemicalFormula(line) }}
            />
          ))}
        </div>
        <div className={styles.form}>
          <input
            type="text"
            placeholder="Enter prompt..."
            className={styles.input}
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleGenerate();
              }
            }}
          />
          <div className={styles.buttonGroup}>
            <button onClick={handleGenerate} className={styles.generateBtn}>
              Generate
            </button>
            <button className={styles.stopBtn}>Stop</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InnovartanAI;
