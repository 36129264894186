import React, { forwardRef } from 'react';

const Input = forwardRef(({ onSubmit }, ref) => {
  const [content, setContent] = React.useState('');

  const handleSubmit = () => {
    onSubmit(content);
    setContent('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <input
      type="text"
      ref={ref}
      className="border-b mt-3 w-[80%] outline-none border-b-gray-100 focus:border-gray-400 placeholder:text-sm"
      placeholder="Enter your comment..."
      value={content}
      onKeyDown={handleKeyDown}
      onChange={(e) => setContent(e.target.value)}
    />
  );
});

export default Input;
