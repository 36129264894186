import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TestList from "./Components/Pages/TestList/TestList";
import NoPage from "./Components/Pages/NoPage/NoPage";
import { getUser } from "./Store/API/API";
import { useDispatch, useSelector } from "react-redux";
import { userDataActions } from "./Store/Data/Slices/UserDataSlice";
import TestSubmision from "./Components/Pages/TestSubmision/TestSubmision";
import ViewTestSubmission from "./Components/Pages/ViewTestSubmission/ViewTestSubmission";
import Login from "./Components/Pages/Login/Login";
import Dashboard from "./Components/Pages/Dashboard/Dashboard";
import Post from "./Components/Pages/Post/Post";
import BatchAssignments from "./Components/Pages/Assignment/BatchAssignments";
import AssignmentDetails from "./Components/Pages/Assignment/AssignmentDetails";
import AssignmentSubmit from "./Components/Pages/Assignment/AssignmentSubmit";
import Profile from "./Components/Pages/Profile/Profile";
import ChangePassword from "./Components/Pages/ChangePassword/ChangePassword";
import UpcommingSession from "./Components/Pages/Session/Upcomming";
import PastSession from "./Components/Pages/Session/Past";
import CancelSession from "./Components/Pages/Session/Cancel";
import QuestionBank from "./Components/Pages/QuestionBank/QuestionBank";
import Report from "./Components/Pages/Report/Report";
import Resources from "./Components/Pages/Resources/Resources";
import Feedback from "./Components/Pages/Feedback/Feedback";
import Batch from "./Components/Pages/Batch/Batch";
import Payment from "./Components/Pages/Payment/Payment";
import Cookies from "universal-cookie";
import MiniDrawer from "./Components/Pages/Navbar/Drawer";
import TaxInvoice from "./Components/Pages/TaxInvoice/TaxInvoice";
import { applicationActions } from "./Store/Data/Slices/ApplicationSlice";
import TestScreen1 from "./Components/Pages/Test/TestScreen1";
import App from "./App";
import Courses from "./Components/Pages/Courses/Course";
import CourseDetails from "./Components/Pages/Courses/courseDetails";
import CourseContent from "./Components/Pages/Courses/CourseContent";
import TeacherTestView from "./Components/Pages/TestDetails/TeacherTestView/TeacherTestView";
import InnovartanAI from "./Components/Pages/InnovartanAI/InnovartanAI";
import TeacherSubmissionView from "./Components/Pages/TestDetails/TeacherSubmissionView/TeacherSubmissionView";
const RootPage = () => {
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const cookies = new Cookies();

  const fetchAuthUser = async (auth) => {
    // console.log("Fetching Auth User", auth);
    const data = await getUser(auth,dispatch);
    let tempData = {
      token: data?.token,
      userId: data?.user_id,
      testId: data?.test_id,
      groupId: data?.test_group_id,
      firstName: data?.first_name,
      lastName: data?.last_name,
    };
    dispatch(userDataActions.setUserData(tempData));
    // console.log("User Data Using Auth ", tempData);
  };

  useEffect(() => {
    const params = window.location.search;
    const auth = new URLSearchParams(params).get("auth");

    if (auth) {
      fetchAuthUser(auth);
    } else {
      // console.log("No auth token, Bridge Implimenting...");
      // fetchAuthUser("WZpfrnveovCvsGeWX2OYkIOU");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // const cookieData = cookies.get("userData");
    const cookieData = JSON.parse(localStorage.getItem("userData"));
    const batchData = JSON.parse(localStorage.getItem("batchData"));
    // console.log("Cookie Data", cookieData);
    dispatch(userDataActions.setUserData(cookieData));
    dispatch(applicationActions.setBatchData(batchData));
  }, []);

  if (!userState?.token) {
    // console.log("user not found", userState);
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <MiniDrawer>
              <Dashboard />
            </MiniDrawer>
          }
        />
        <Route
          path="/courses"
          element={
            <MiniDrawer>
              <Courses />
            </MiniDrawer>
          }
        />
        <Route
          path="/course-details"
          element={
            <MiniDrawer>
              <CourseDetails />
            </MiniDrawer>
          }
        />
        <Route
          path="/course-content"
          element={
            <MiniDrawer>
              <CourseContent />
            </MiniDrawer>
          }
        />
        <Route
          path="/testList"
          element={
            <MiniDrawer>
              <TestList />
            </MiniDrawer>
          }
        />
        <Route
          path="/teacher-test-view"
          element={
            <MiniDrawer>
              <TeacherTestView />
            </MiniDrawer>
          }
        />
        <Route
          path="/teacher-submission-view"
          element={
            <MiniDrawer>
              <TeacherSubmissionView />
            </MiniDrawer>
          }
        />
        <Route
          path="/assignment"
          element={
            <MiniDrawer>
              <BatchAssignments />
            </MiniDrawer>
          }
        />
        <Route
          path="/assignment/details"
          element={
            <MiniDrawer>
              <AssignmentDetails />
            </MiniDrawer>
          }
        />
        <Route
          path="/assignment/submittions"
          element={
            <MiniDrawer>
              <AssignmentSubmit />
            </MiniDrawer>
          }
        />
        <Route
          path="/post"
          element={
            <MiniDrawer>
              <Post />
            </MiniDrawer>
          }
        />
        <Route
          path="/resources"
          element={
            <MiniDrawer>
              <Resources />
            </MiniDrawer>
          }
        />
        <Route
          path="/profile"
          element={
            <MiniDrawer>
              <Profile />
            </MiniDrawer>
          }
        />
        <Route
          path="/change-password"
          element={
            <MiniDrawer>
              <ChangePassword />
            </MiniDrawer>
          }
        />
        <Route
          path="/upcomming-session"
          element={
            <MiniDrawer>
              <UpcommingSession />
            </MiniDrawer>
          }
        />
        <Route
          path="/past-session"
          element={
            <MiniDrawer>
              <PastSession />
            </MiniDrawer>
          }
        />
        <Route
          path="/feedback"
          element={
            <MiniDrawer>
              <Feedback />
            </MiniDrawer>
          }
        />
        <Route
          path="/report"
          element={
            <MiniDrawer>
              <Report />
            </MiniDrawer>
          }
        />
        <Route
          path="/cancel-session"
          element={
            <MiniDrawer>
              <CancelSession />
            </MiniDrawer>
          }
        />
        <Route path="/question-bank" element={<QuestionBank />} />
        <Route
          path="/resources"
          element={
            <MiniDrawer>
              <Resources />
            </MiniDrawer>
          }
        />
        <Route
          path="/batch"
          element={
            <MiniDrawer>
              <Batch />
            </MiniDrawer>
          }
        />

        <Route
          path="/payment"
          element={
            <MiniDrawer>
              <Payment />
            </MiniDrawer>
          }
        />
        <Route path="/startTest" element={<App />} />
        <Route path="/test/retest/submission" element={<TestSubmision />} />
        <Route
          path="/ViewTestSubmission"
          element={
            <MiniDrawer>
              <ViewTestSubmission />
            </MiniDrawer>
          }
        />
        <Route path="/taxInvoice" element={<TaxInvoice />} />
        <Route path="*" element={<NoPage />} />
        <Route
          path="/innovartan-ai"
          element={
            <MiniDrawer>
              <InnovartanAI />
            </MiniDrawer>
          }
        />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* {userState?.token ? (
          <Route path="/dashboard" element={<Dashboard />} />
        ) : (
          <Route path="/dashboard" element={<Navigate to="/login" />} />
        )} */}
        {/* <Route path="/test/:testId" element={<h1>"Hello"</h1>} /> */}
      </Routes>
    </BrowserRouter>
  );
};

export default RootPage;
