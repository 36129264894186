// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TestInfoCard_TestInfoCardOuterContainer__qlMvL {
  display: flex;
  flex-direction: column;
  width: 24%;
  background-color: #ffffff;
  box-shadow: 2px 4px 4px rgba(0, 4, 4, 0.15);
  border-radius: 5px;
  justify-content: center;
  padding: 10px;
}

@media (max-width: 768px) {
  .TestInfoCard_TestInfoCardOuterContainer__qlMvL {
    width: 49%;
    margin-bottom: 10px;
  }
}
.TestInfoCard_testCardHeading__1iz7I {
  font-size: 15px;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.TestInfoCard_testCardText__ICJAM {
  font-size: 17px;
  font-weight: 600;
  color: #ff9330;
  margin: 0;
}

@media (max-width: 425px) {
  .TestInfoCard_testCardText__ICJAM {
    font-size: 13px;
  }
  .TestInfoCard_testCardHeading__1iz7I {
    font-size: 13px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/ViewTestSubmission/TestInfoCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU;EACV,yBAAyB;EACzB,2CAA2C;EAC3C,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE;IACE,UAAU;IACV,mBAAmB;EACrB;AACF;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,SAAS;AACX;;AAEA;EACE;IACE,eAAe;EACjB;EACA;IACE,eAAe;EACjB;AACF","sourcesContent":[".TestInfoCardOuterContainer {\n  display: flex;\n  flex-direction: column;\n  width: 24%;\n  background-color: #ffffff;\n  box-shadow: 2px 4px 4px rgba(0, 4, 4, 0.15);\n  border-radius: 5px;\n  justify-content: center;\n  padding: 10px;\n}\n\n@media (max-width: 768px) {\n  .TestInfoCardOuterContainer {\n    width: 49%;\n    margin-bottom: 10px;\n  }\n}\n.testCardHeading {\n  font-size: 15px;\n  font-weight: 500;\n  color: #000000;\n  margin: 0;\n}\n\n.testCardText {\n  font-size: 17px;\n  font-weight: 600;\n  color: #ff9330;\n  margin: 0;\n}\n\n@media (max-width: 425px) {\n  .testCardText {\n    font-size: 13px;\n  }\n  .testCardHeading {\n    font-size: 13px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TestInfoCardOuterContainer": `TestInfoCard_TestInfoCardOuterContainer__qlMvL`,
	"testCardHeading": `TestInfoCard_testCardHeading__1iz7I`,
	"testCardText": `TestInfoCard_testCardText__ICJAM`
};
export default ___CSS_LOADER_EXPORT___;
