// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.student-feedback {
  
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.student-feedback h1 {
  text-align: center;
  margin-bottom: 20px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 40%; /* Adjust width to make four groups fit in one row */
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.feedback-section {
  margin-bottom: 20px;
}

.feedback-header {
  display: flex;
  justify-content: space-between;
  background-color: #000080;
  color: white;
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
}

.feedback-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.feedback-item label {
  flex: 1 1;
}

.feedback-item input[type="radio"] {
  margin: 0 10px;
  width: 20px;
  height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Feedback/Feedback.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,UAAU,EAAE,oDAAoD;AAClE;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,SAAO;AACT;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd","sourcesContent":[".student-feedback {\n  \n  padding: 20px;\n  background-color: #f9f9f9;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n}\n\n.student-feedback h1 {\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.form-header {\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 20px;\n}\n\n.form-group {\n  display: flex;\n  flex-direction: column;\n  width: 40%; /* Adjust width to make four groups fit in one row */\n}\n\n.form-group label {\n  margin-bottom: 5px;\n  font-weight: bold;\n}\n\n.form-group select {\n  padding: 10px;\n  border: 1px solid #ccc;\n  border-radius: 4px;\n}\n\n.feedback-section {\n  margin-bottom: 20px;\n}\n\n.feedback-header {\n  display: flex;\n  justify-content: space-between;\n  background-color: #000080;\n  color: white;\n  padding: 10px;\n  border-radius: 4px;\n  font-weight: bold;\n}\n\n.feedback-item {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 10px 0;\n}\n\n.feedback-item label {\n  flex: 1;\n}\n\n.feedback-item input[type=\"radio\"] {\n  margin: 0 10px;\n  width: 20px;\n  height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
