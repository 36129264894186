import React, { useEffect, useState } from "react";
import classes from "./TestList.module.css";
import TestSearchTable from "../../UserInterface/TestList/TestSearchTable";
import TestCards from "../../UserInterface/TestList/TestCards";
import { getTestList } from "../../../Store/API/API";
import { useSelector ,useDispatch} from "react-redux";
import Logout from "../Logout/Logout";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
import CardView from "../Session/CardView";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

const TestList = () => {
  const dispatch=useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);
  const navigate = useNavigate();
  const [testList, setTestList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = async () => {
    if (userState.token) {
      const data = await getTestList(
        userState.userId,
        batchState.groupId,
        userState.token,
        dispatch
      );
      setTestList(data);
      setIsLoading(false);
      // console.log([0].quiz_id, "quix id");
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [userState.token]);

  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const testData = (item) => (
    <>
      <div>
        <strong>Titel:</strong> <span>{item.title}</span>
      </div>
      <div>
        <strong>Publish Date:</strong> <span>To {item.due_date}</span>
      </div>
      <div>
        <strong>Subject:</strong> <span>{item.test_subjects}</span>
      </div>
      <div>
        <strong>Chapter:</strong>{" "}
        <span>
          {item.test_chapters?.map((chapter, index) => {
            return (
              <span key={index} className={classes.para}>
                {chapter},
              </span>
            );
          })}
        </span>
      </div>
      <div>
        <strong>Test Mode:</strong> <span>{item.test_mode}</span>
      </div>
      <div>
        <strong>Rank:</strong> <span>{item.rank}</span>
      </div>
      <div className="h-10" style={{ display: "flex" }}>
        <strong style={{ marginRight: "10px" }}>Action: </strong>
        <span>
          {item.take_test === "1" && (
            <span
              onClick={() => {
                navigate("/startTest", { state: { quizId: item.quiz_id } });
              }}
              className={classes.takeButton}
            >
              Take Test
            </span>
          )}
          {item.take_test === "3" && (
            <div>
              <span
                className={classes.takeButton}
                onClick={() => {
                  navigate("/ViewTestSubmission", {
                    state: { quizId: item.quiz_id },
                  });
                }}
              >
                Evaluation
              </span>
              <span
                className={classes.takeButton}
                onClick={() => {
                  navigate("/ViewTestSubmission", {
                    state: { quizId: item.quiz_id },
                  });
                }}
                style={{ marginLeft: "10px" }}
              >
                Analytics
              </span>
            </div>
          )}
          {item.take_test === "4" && (
            <span className={classes.takeButton}>Awaiting Evaluation</span>
          )}
        </span>
      </div>
    </>
  );

  return (
    <div className={classes.TestListContainer}>
      {/* <div className={classes.TopBar}>
        <h2>Tests</h2>
        <Logout />
        <div className={classes.TopBarContainer}>
          <div className={classes.TopTab}>
            <h5>All</h5>
          </div>
          <div className={classes.TopTab}>
            <h5>Pending</h5>
          </div>
          <div className={classes.TopTabActive}>
            <h5>Attempted</h5>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            className={classes.infoCircle}
            style={{ backgroundColor: "#FF9700", marginRight: "5px" }}
          ></div>
          <p className={classes.infoText} style={{ marginRight: "5px" }}>
            Evaluated
          </p>
          <div
            className={classes.infoCircle}
            style={{ backgroundColor: "#999999" }}
          ></div>
          <p className={classes.infoText}>Not Evaluated</p>
        </div>
      </div> */}
      <div
        className="overflow-y-auto max-h-[80vh] hidden sm:block"
        style={{ paddingBottom: "20px" }}
      >
        <Table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <Thead>
            <Tr className="bg-[#0A3C87] text-white uppercase text-sm leading-normal">
              <Th className="py-2 px-0 text-center">Title</Th>
              <Th className="py-2 px-0 text-center">Publish Date</Th>
              <Th className="py-2 px-0 text-center">Subject</Th>
              <Th className="py-2 px-0 text-center">Chapter</Th>
              <Th className="py-2 px-0 text-center">Test Mode</Th>
              <Th className="py-2 px-0 text-center">Rank</Th>
              <Th className="py-2 px-0 text-center">Actions</Th>
            </Tr>
          </Thead>
          <Tbody className="text-gray-600 text-sm font-light">
            {testList?.length === 0 && (
              <Tr>
                <Td
                  colSpan="7"
                  className="py-2 px-6 text-center text-orange-500 font-bold"
                >
                  No Payments Found
                </Td>
              </Tr>
            )}

            {testList?.map((test, index) => (
              <Tr
                className="border-b border-gray-200 hover:bg-gray-100"
                key={index}
              >
                <Td className="py-2 px-0 text-center">{test.title || "-"}</Td>
                <Td className="py-2 px-0 text-center">
                  {test.assign_date || "-"}
                </Td>
                <Td className="py-2 px-0 text-center">
                  {test.test_subjects?.map((sub, index) => {
                    return (
                      <p
                        key={index}
                        className={classes.para}
                        style={{ margin: 0 }}
                      >
                        {sub}
                      </p>
                    );
                  })}
                </Td>
                <Td className="py-2 px-0 text-center">
                  {test.test_chapters?.map((sub, index) => {
                    return (
                      <p
                        key={index}
                        className={classes.para}
                        style={{ margin: 0 }}
                      >
                        {sub}
                      </p>
                    );
                  }) || "-"}
                </Td>
                <Td className="py-2 px-0 text-center">
                  {test.test_mode || "-"}
                </Td>
                <Td className="py-2 px-0 text-center">{test.rank || "-"}</Td>
                <Td className="py-3 px-6 text-center">
                  <TestCards
                    key={index}
                    type={test.type}
                    title={test.title}
                    Actions={test.Actions}
                    Rank={test.rank}
                    TestMode={test.test_mode}
                    chapters={test.test_chapters}
                    publishFrom={test.assign_date}
                    publishTo={test.due_date}
                    subject={test.test_subjects}
                    quizId={test.quiz_id}
                    token={test.token}
                    takeTest={test.take_test}
                    analyticURL={test.analytic_url}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
      {/* <div className={classes.TestSearchTable}>
        <TestSearchTable />
      </div>
      <div className={classes.TestCardsContainer}>
        <div className={classes["TestCardsScrollable"]}>
          {testList?.length === 0 && (
            <div className={classes.noTest}>
              <h3>No Tests Available</h3>
            </div>
          )}
          {testList?.map((test, index) => {
            // console.log(test, "test");
            return (
              <TestCards
                key={index}
                type={test.type}
                title={test.title}
                Actions={test.Actions}
                Rank={test.rank}
                TestMode={test.test_mode}
                chapters={test.test_chapters}
                publishFrom={test.assign_date}
                publishTo={test.due_date}
                subject={test.test_subjects}
                quizId={test.quiz_id}
                token={test.token}
                takeTest={test.take_test}
                analyticURL={test.analytic_url}
              />
            );
          })}
        </div>
      </div> */}
      <div className={classes.cardview}>
        <div className="mr-1 ml-1">
          <CardView
            data={testList}
            renderContent={testData}
            noDataMessage={"No Tests Available"}
          />
        </div>
      </div>
    </div>
  );
};

export default TestList;
