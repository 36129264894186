import React, { useRef, useState } from "react";
import { TiAttachmentOutline } from "react-icons/ti";
import { FaPaperPlane } from "react-icons/fa";
import Button from "../Button/Button";
import { handleFileChange } from "../../../Store/API/File";
import { handleCreatePost } from "../../../Store/API/User";
import { useDispatch, useSelector } from "react-redux";
const custom_class = "bg-orange-400 text-white h-10 px-4 rounded-2xl ";
const Chat = ({ feed, setFetchAgain }) => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const contentRef = useRef();
  const dispatch=useDispatch();
  const userState = useSelector((state) => state.userData.userData);
  const batchState = useSelector((state) => state.applicationData.batchData);

  const token = userState?.token;
  const group_id = batchState?.groupId;
  const user_id = userState?.userId;
  const group_owner_id = 4;

  const handleOnChange = async (e) => {
    const selectedFiles = Array.from(e.target.files);
    setIsLoading(true);
    console.log(selectedFiles);

    const filesData = await Promise.all(
      selectedFiles.map(async (file) => {
        const { data } = await handleFileChange(file,dispatch);
        // console.log(data);

        let fileSizeInKB = data.file_size / 1024;
        fileSizeInKB = fileSizeInKB.toFixed(2);

        return {
          name: data.file_name,
          owner_id: feed.group_owner_id,
          url: data.file_url,
          mime: data.file_type,
          size: fileSizeInKB + " KB",
          size_bytes: data.file_size,
        };
      })
    );

    setIsLoading(false);
    setFiles([...files, ...filesData]);
  };

  const removeFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);
  };

  const handleSubmitPost = async () => {
    const content = contentRef.current.value;
    if (content.length === 0) return;
    const strAttachments = JSON.stringify(files);
    const data = await handleCreatePost(
      token,
      group_id,
      user_id,
      group_owner_id,
      content,
      strAttachments,dispatch
    );
    setFiles([]);
    contentRef.current.value = "";
    setFetchAgain((prev) => !prev);
  };

  return (
    <div className="chat flex w-full rounded-lg py-4 px-4 mb-3 md:p-12 border border-gray-300 bg-white">
      <div className="border-2 border-gray-500 rounded-full w-12 h-12 p-2 mr-7 mt-2">
        <img
          className="w-full h-full object-contain"
          src={"logo192.png"}
          alt="Profile"
        />
      </div>
      <div className="relative flex-1 w-full">
        <div className="post-section">
          <textarea
            ref={contentRef}
            className="w-full h-32 p-4 border-2 rounded-3xl outline-none focus:border-orange-300"
            placeholder="Write your comment here..."
          ></textarea>
          <input
            type="file"
            multiple
            onChange={(e) => handleOnChange(e)}
            style={{ display: "none" }}
            id="fileInput"
          />
          <div className="absolute right-3 top-20 flex gap-2 items-end">
            {isLoading && (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="inline w-4 h-4 text-orange-400 animate-spin dark:text-gray-600 fill-orange-400"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            )}
            <label htmlFor="fileInput">
              <TiAttachmentOutline
                color="orange"
                className="bottom-4 cursor-pointer"
                size={28}
              />
            </label>
            <Button
              custom_class={`${custom_class} hidden lg:block`}
              onClick={handleSubmitPost}
              text={"Send Message"}
            />
            <Button
              custom_class={`${custom_class} lg:hidden`}
              onClick={handleSubmitPost}
              text={<FaPaperPlane />}
            />
          </div>
        </div>

        {files.length > 0 && (
          <div className="flex gap-2 flex-wrap w-[80%]">
            {files.map((file, index) => (
              <div
                key={index}
                className="flex w-[8rem] px-3 py-2 truncate items-center p-1 bg-gray-200 rounded-md"
              >
                <p className="truncate text-sm text-slate-700">
                  {file.name} ({file.size})
                </p>
                <button
                  onClick={() => removeFile(index)}
                  className="ml-2 text-red-500 hover:text-red-700"
                >
                  &times;
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
