import React, { useEffect, useState } from "react";
import DropDown from "../../UI/DropDown/DropDown";
import AttachmentBox from "../../UI/Box/AttachmentBox";
import { fetchSubmissionDetails } from "../../../Store/API/User";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../UserInterface/Spinner/Spinner";

const AssignmentSubmit = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch=useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const currentAssignmentId = location.state.assignmentId;

  const userState = useSelector((state) => state.userData.userData);

  const token = "98c1ec61f46858ef5bcce7f85f5286cbtutMjk0Mg==";
  const studentId = userState?.userId;
  const assignmentId = currentAssignmentId;

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await fetchSubmissionDetails(
          token,
          studentId,
          assignmentId,dispatch
        );
        // console.log(result);
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
        setIsLoading(false);
      }
    };

    getData();
  }, []);

  const options = {
    "due-date": ["Today", "Tomorrow", "Next week"],
    attachments: ["Assignment 1", "Assignment 2", "Assignment 3"],
    actions: ["Edit", "Delete", "View details"],
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="flex h-[95vh] w-[100%] overflow-auto">
      <div className="batch-assignments dashboard-content overflow-auto flex md:w-[100%] w-full flex-col px-3 py-10 h-full bg-gray-100 mt-10 ">
        <div className="form-row">
          <div className="form-buttons">
            <button
              type="submit"
              style={{
                backgroundColor: "#fff",
                borderColor: "#FEA63C",
                borderWidth: "2px",
                borderStyle: "solid",
                color: "#000",
                margin: "0 10px",
                width: "120px",
                height: "60px",
                textAlign: "center",
                padding: "5px",
              }}
              onClick={(event) => {
                event.preventDefault();
                navigate("/assignment/details", {
                  state: { assignmentId: currentAssignmentId },
                });
              }}
            >
              Assignment Details
            </button>
            &nbsp;
            <button
              type="submit"
              style={{
                backgroundColor: "#ff3d5d",
                borderColor: "#ff3d5d",
                color: "#fff",
                margin: "0 10px",
                width: "120px",
                height: "60px",
                textAlign: "center",
                padding: "5px",
              }}
            >
              Submissions
            </button>
            &nbsp;
          </div>
        </div>
        <div className="flex flex-col p-3 bg-white rounded-lg border-t-2 border-t-orange-500">
          <div className="py-8">
            {data && data.submission_details.length > 0 ? (
              <div className="">
                {data.submission_details.map((submission, index) => (
                  <div
                    key={index}
                    className="border-b bg-gray-100 mb-4 p-4 rounded-lg shadow-md border-gray-200 py-4"
                  >
                    <h4 className=" font-bold mb-2 text-blue-800">
                      Submission #{index + 1}
                      <p className="text-lg text-gray-600 mb-1">
                        Title : {submission.submission.title}
                      </p>
                    </h4>
                    <p className="text-sm text-gray-600 mb-1">
                      Submitted on : {submission.submission.submit_date} at{" "}
                      {submission.submission.submit_time}
                    </p>
                    <p className="mb-2">
                      Remark : {submission.submission.comment}
                    </p>
                    <div className="flex flex-wrap">
                      <div className="attachment my-2 w-full md:w-1/2">
                        {submission.submission.attachments.length > 0 && (
                          <div className="mb-2 w-full h-full flex flex-col justify-center">
                            <h6 className="my-3">Attachments:</h6>
                            <div className="flex flex-wrap">
                              {submission.submission.attachments.map(
                                (attachment, idx) => (
                                  <AttachmentBox
                                    key={idx + 50}
                                    attachment={attachment}
                                  />
                                )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="evaluation my-2 w-full md:w-1/2">
                        {submission.evaluation.evaluation_id && (
                          <div className="mb-2 w-full h-full flex flex-col justify-center">
                            <h6 className="my-3">Evaluation:</h6>
                            <div className="flex flex-wrap">
                              {submission.evaluation.attachments.length > 0 && (
                                <>
                                  {submission.evaluation.attachments.map(
                                    (attachment, index) => (
                                      <AttachmentBox
                                        key={index + 100}
                                        attachment={attachment}
                                      />
                                    )
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-xl text-[#F3A041]">
                  No submissions available.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentSubmit;
