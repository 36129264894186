import React, { useState, useEffect } from "react";
import "./ChangePassword.css";
import { changePassword } from "../../../Store/API/API";
import { useSelector } from "react-redux";

const ChangePassword = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [error, setError] = useState("");

  const [passwordData, setPasswordData] = useState({
    current_password: "",
    new_password: "",
    confirm_password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handleBlur = (e) => {
    if (passwordData.new_password !== passwordData.confirm_password) {
      setError("Passwords do not match");
    } else {
      setError("");
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(passwordData);
    changePassword(userState.token, passwordData);
  };

  return (
    <div className="flex h-[95vh] w-[100%]">
      <div className="flex md:w-[100%] w-full flex-col px-10 py-10 h-full bg-gray-100 mb-10 ">
        <div className="edit-profile">
          <div className="form-row">
            <div className="form-group">
              <h2>Change Password</h2>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label>Current Password</label>
                <input
                  type="password"
                  name="current_password"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>New Password</label>
                <input
                  type="password"
                  name="new_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group">
                <label>Confirm New Password</label>
                <input
                  type="password"
                  name="confirm_password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <div className="form-buttons">
              <button type="button">Cancel</button>
              <button type="submit">Change</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
