import { createSlice } from "@reduxjs/toolkit";
const answerSlice = createSlice({
  name: "answers",
  initialState: {
    answer: [],
    attemptId: "",
    mendatory: {},
    mendatoryCount: {
      Biology: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
      Physics: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
      Chemistry: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
      Maths: {
        section_one_mandetory: 0,
        section_two_mandetory: 0,
      },
    },
  },
  reducers: {
    setAnswer: (state, action) => {
      state.answer = action.payload;
    },
    updateAnswer: (state, action) => {
      const { questionId, value } = action.payload;

      const answerIndex = state.answer.findIndex(
        (answer) => answer.questionId === questionId
      );
      state.answer[answerIndex].answer = value;
      state.answer[answerIndex].question_state = "Answered";
    },
    updateQuestionState: (state, action) => {
      const { questionId, value } = action.payload;
      // console.log(questionId, value, "questionId, value");
      const answerIndex = state.answer.findIndex(
        (answer) => answer.questionId === questionId
      );
      state.answer[answerIndex].question_state = value;
    },
    updateQuestionStateByPosition: (state, action) => {
      const { position } = action.payload;
      // console.log(position, "position");
      const answerIndex = state.answer.findIndex(
        (answer) => answer.position === position
      );
      state.answer[answerIndex].question_state = "Not Answered";
    },
    updateAnswerTime: (state, action) => {
      let seconds = 0;
      const { questionId, startTime, endTime } = action.payload;
      const answerIndex = state.answer.findIndex(
        (answer) => answer.questionId === questionId
      );
      if (startTime && endTime) {
        const differenceInMilliseconds = endTime - startTime;
        seconds = Math.floor(differenceInMilliseconds / 1000);
      }

      state.answer[answerIndex].attempt_time =
        state.answer[answerIndex].attempt_time + seconds;
    },
    updateMendatory: (state, action) => {
      state.mendatory = action.payload;
    },
    updateMendatoryCount: (state, action) => {
      const { subject, section } = action.payload;
      state.mendatoryCount[subject][section] =
        state.mendatoryCount[subject][section] + 1;
    },
    reduceMendatoryCount: (state, action) => {
      const { subject, section } = action.payload;
      state.mendatoryCount[subject][section] =
        state.mendatoryCount[subject][section] - 1;
    },
    setAttemptId: (state, action) => {
      state.attemptId = action.payload;
    },
  },
});
export const answerActions = answerSlice.actions;
export default answerSlice;
