import React, { useState, useEffect } from "react";
import classes from "./Dashboard.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  dashboardCourse,
  dashboardSession,
  dashboardTest,
  dashboardVedio,
  getSessionJoinningUrl,
  getVedioViewTime,
} from "../../../Store/API/API";
import VideoPlayer from "./Videoplayer/VideoPlayer";
import Spinner from "../../UserInterface/Spinner/Spinner";
import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  const userState = useSelector((state) => state.userData.userData);
  const [dashboardCourses, setDashboardCourses] = useState([]);
  const [dashboardVedios, setDashboardVedios] = useState([]);
  const [dashboardTests, setDashboardTests] = useState([]);
  const [dashboardSessions, setDashboardSessions] = useState([]);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState("");
  const [selectedMP4, setSelectedMP4] = useState();
  const [currentViewTime, setCurrentViewTime] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch= useDispatch();
  const fetchTests = async () => {
    const data = await dashboardTest(userState.userId,dispatch);
    if (data) {
      setDashboardTests(data);
    }
    setIsLoading(false);
  };
  const fetchCourses = async () => {
    const data = await dashboardCourse(userState.userId, "shop");
    if (data) {
      setDashboardCourses(data.shop_data);
    }
    setIsLoading(false);
  };
  const fetchVedios = async () => {
    const data = await dashboardVedio(userState.userId, "video",dispatch);
    if (data) {
      setDashboardVedios(data.videos);
    }
    setIsLoading(false);
  };
  const fetchSession = async () => {
    const data = await dashboardSession(userState.userId, "session",dispatch);
    if (data) {
      // console.log(data.session);
      setDashboardSessions(data.session);
    }
    setIsLoading(false);
  };
  const handleJoinSession = async (event, session, batchId) => {
    event.preventDefault();
    // console.log(session, batchId);
    const data = await getSessionJoinningUrl(
      userState.token,
      userState.userId,
      session,
      batchId,dispatch
    );
    
    if (data) {
      // console.log(data);
      window.location.href = data.meetingUrl;
    }
  };
  const openVideoModal = async (videoUrl, data) => {
    setSelectedVideoUrl(videoUrl);
    // console.log("my data=", data);
    setSelectedMP4(data);
    const tempData = {
      student_id: userState.userId,
      content_type: "shop",
      content_id: data.content_id,
    };
    const apiData = await getVedioViewTime(tempData, userState.token,dispatch);
    if (apiData) {
      // console.log(" before current time ",currentViewTime);
      setCurrentViewTime(apiData.data.current_view_time);
      setShowVideoModal(true);
    }
    // console.log(apiData);
    // setShowVideoModal(true);
  };

  const closeVideoModal = () => {
    setSelectedVideoUrl("");
    setSelectedMP4();
    setCurrentViewTime(0);
    setShowVideoModal(false);
  };
  useEffect(() => {
    // API call to get courses
    fetchCourses();
    // API call to get vedios
    fetchVedios();
    // API call to get tests
    fetchTests();
    // API call to get session
    fetchSession();
    // eslint-disable-next-line
  }, [userState]);

  const SectionCard = ({ cardType, data, isVedio, isCourse }) => {
    return (
      <div
        className={classes.sectionCard}
        onClick={() => {
          if (isCourse) {
            // console.log(data);
            navigate("/course-details", {
              state: { course_id: data.course_id },
            });
          }
        }}
        style={{ cursor: isCourse ? "pointer" : "default" }}
      >
        {cardType === "normal" ? (
          isVedio === true ? (
            <div className={classes.vedioContainer}>
              <i
                className={`fa-regular fa-circle-play fa-3x ${classes.vedioThumbnail} `}
                style={{ cursor: "pointer" }}
                onClick={() => openVideoModal(data?.url, data)}
              ></i>
              {data?.thumb_nail ? (
                <img
                  alt="thumbnail"
                  src={data?.thumb_nail}
                  style={{ cursor: "pointer" }}
                  className={classes.cardImage}
                  onClick={() => openVideoModal(data?.url, data)}
                ></img>
              ) : (
                <div
                  className={classes.cardImage3}
                  onClick={() => openVideoModal(data?.url, data)}
                ></div>
              )}
            </div>
          ) : (
            <img
              alt="thumbnail"
              src={data?.cover_photo_url || data?.thumb_nail}
              className={classes.cardImage}
            ></img>
          )
        ) : (
          <img
            alt="thumbnail"
            src={"/images/sessionLogo.png"}
            className={classes.cardImage2}
          ></img>
        )}
        {/* {cardType === "normal" ? (
          <img
            src={data?.cover_photo_url || data?.thumb_nail}
            className={classes.cardImage}
          ></img>
        ) : (
          <div className={classes.cardImage2}></div>
        )} */}
        <div className={classes.contentContainer}>
          <p className={classes.cardHeading}>{data?.name}</p>
          {!isVedio && (
            <p className={classes.cardHeading}>
              {data?.curriculum || data?.batch_name} |{" "}
              {data?.class || `${data?.fromTime} - ${data?.toTime}`} |{" "}
              {data?.subject || data?.date}
            </p>
          )}
          {isVedio && <p className={classes.cardHeading}>{data?.title}</p>}
          <p className={classes.cardSubHeading}>By: Innovartan</p>
          {cardType === "session" && (
            <div className={classes.cardButton}>
              <button
                className={classes.cardButton}
                onClick={(e) => {
                  handleJoinSession(e, data.sessionId, data.batch_id);
                }}
              >
                Join now
              </button>
            </div>
          )}
        </div>
      </div>
    );
  };
  const TestCard = ({ subject, completed, data }) => {
    const colorMap = {
      Physics: { backgroundColor: "#BAE5F5", lineColor: "#000E8F" },
      Chemistry: { backgroundColor: "#FDE1AC", lineColor: "#FF9330" },
      Maths: { backgroundColor: "#CCEFBF", lineColor: "#44FB00" },
      Biology: { backgroundColor: "#D9D9D9", lineColor: "#717171" },
    };
    const testStatus = {
      0: "Upcomping",
      1: "Take Test",
    };
    return (
      <div
        className={classes.testCard}
        style={{ backgroundColor: colorMap[subject]?.backgroundColor }}
      >
        <p className={classes.testStatus}>{testStatus[data?.take_test]}</p>
        <div
          className={classes.testHorizontalLine}
          style={{ backgroundColor: colorMap[subject]?.lineColor }}
        ></div>
        <img
          src={`/images/${subject}.png`}
          alt=""
          className={classes.testImage}
        />
        <p className={classes.testHeading}>
          {subject} - {data?.title}
        </p>
        <p className={classes.testSubheading}>
          Test Duration: {data?.timelimit || "6 hours"}
        </p>
        {data?.take_test === 1 && (
          <button
            className={classes.testButton}
            onClick={() => {
              document.location.href = data?.take_test_url;
            }}
          >
            {testStatus[data?.take_test]}
          </button>
        )}
        {data?.take_test === 0 && (
          <button className={classes.testButtonDisabled} disabled>
            {testStatus[data?.take_test]}
          </button>
        )}
      </div>
    );
  };
  const Section = ({ heading, cardType, test, data, isVedio, isCourse }) => {
    return (
      <div className={classes.section}>
        <div className={classes.sectionOuter}>
          <div>
            <i className="fa-solid fa-book-open"></i>
            <span style={{ marginLeft: "10px" }}>{heading}</span>
          </div>
        </div>
        <div className={classes.horizontalLine}></div>
        <div className={classes.sectionCardContainer}>
          {!test &&
            data?.map((test, index) => (
              <SectionCard
                cardType={cardType}
                data={test}
                key={index}
                isVedio={isVedio}
                isCourse={isCourse}
              />
            ))}
        </div>
        <div className={classes.sectionCardContainer}>
          {test &&
            data?.map((test, index) => (
              <TestCard
                subject={test?.test_subjects[0]}
                completed={test?.completed}
                key={index}
                data={test}
              />
            ))}
        </div>
      </div>
    );
  };
  if (isLoading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <div className={classes.courseDashboard}>
      {dashboardCourses?.length > 0 ? (
        <Section
          heading={"My Courses"}
          cardType={"normal"}
          test={false}
          isCourse={true}
          data={dashboardCourses}
        />
      ) : null}
      {dashboardVedios?.length > 0 ? (
        <Section
          heading={"Free Videos"}
          cardType={"normal"}
          test={false}
          data={dashboardVedios}
          isVedio={true}
        />
      ) : null}
      {dashboardTests?.length > 0 ? (
        <Section
          heading={"Tests"}
          cardType={"normal"}
          test={true}
          data={dashboardTests}
        />
      ) : null}
      {dashboardSessions?.length > 0 ? (
        <Section
          heading={"Live Sessions"}
          cardType={"session"}
          test={false}
          data={dashboardSessions}
        />
      ) : null}
      {dashboardCourses?.length === 0 &&
        dashboardVedios?.length === 0 &&
        dashboardTests?.length === 0 &&
        dashboardSessions?.length === 0 && (
          <div className={classes.noData}>
            <h5>No Data Found</h5>
          </div>
        )}
      {showVideoModal && (
        <div className={classes.videoModal}>
          <div className={classes.videoModalContent}>
            <span className={classes.close} onClick={closeVideoModal}>
              &times;
            </span>
            <VideoPlayer
              videoUrl={selectedVideoUrl}
              fileData={selectedMP4}
              currentViewTimeParent={currentViewTime}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
